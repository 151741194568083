import React, { Component } from 'react'
import styled from 'styled-components/macro'
import axios from 'axios'

import Welcome from '../Welcome/Welcome'
import Questions from '../Questions/Questions'
import Result from '../Result/Result'
import Header from '../Header/Header'
import Info from './Info'
import Step from '../Step/Step'
import getLink from '../utils/routeNavigation'

const getIE11Overflow = (showInfo, isTall) => {
  if (showInfo || isTall) {
    return 'auto'
  } else {
    return 'hidden'
  }
}

const Main = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    typeof props.step === 'number' ? '#fff' : props.theme.colors.primaryAccent};

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    overflow-x: hidden;
    overflow-y: ${(props) => getIE11Overflow(props.showInfo, props.isTall)};
  }
`

const StepWrapper = styled.div``

const QuestionsWrapper = styled.div`
  flex: 1;
  position: relative;

  @media only screen and (min-width: 768px) {
    display: flex;
    padding-top: 6rem;
    justify-content: center;
  }
`

const HeaderWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`

const getHeaderType = (answers, step) => {
  if (
    typeof step === 'number'
    // typeof step === 'number' ||
    // Object.entries(answers.demographic).length !== 0
  ) {
    return 'back'
  } else {
    return 'messageScreen'
  }
}

class Survey extends Component {
  state = {
    step: 'initial',
    // step: 'result',
    fullAnswers: {},
    answers: { demographic: {}, mindTrack: {}, dq5: {} },
    responseData: null,
    loading: false,
    agreed: null,
    // showInfo: true
    showInfo: false
  }

  async componentDidMount () {
    this.startMachine()
  }

  handleBeginClick = () => {
    const { step } = this.state

    if (step === 'initial') {
      this.setState({ step: 0 })
    }
  }

  startMachine = () => {
    // used to ping server and run it
    const startEndpoint =
      'https://gn3xqh0x8l.execute-api.ap-southeast-2.amazonaws.com/latest/start'
    axios.get(startEndpoint)
  }

  async componentDidUpdate (prevProps) {
    if (
      this.props.finished !== prevProps.finished &&
      this.props.finished === true
    ) {
      this.startMachine()
      this.setState({ step: 'result' })
    }
  }

  handleQuestionAnswered = (title, answer, apiName, value) => {
    const { fullAnswers } = this.state
    let newFullAnswers = {}
    
    if (
      fullAnswers['What is your (biological) sex?'] &&
      title === 'What is your (biological) sex?' &&
      fullAnswers['What is your (biological) sex?'] !== answer[0]
    ) {
      // in case we get back to the first question

      // clean the state
      this.setState({
        fullAnswers: {},
        answers: null
      })

      // level question
      newFullAnswers[title] = answer[0]

      if (answer[1] === 'No') {
        newFullAnswers['What gender do you identify as?'] = answer[0]
      } else if (answer[1] === 'Prefer not to say') {
        newFullAnswers['What gender do you identify as?'] = answer[1]
      }

      this.saveApiResult(apiName, value, true)
      this.getQuestionBranch(title, answer, value, newFullAnswers)
    } else {
      // in case of normal data flow, i.e. we did not get back
      newFullAnswers = { ...fullAnswers }
      if (typeof answer === 'object' && apiName === 'sex') {
        // level question
        newFullAnswers[title] = answer[0]
        if (answer[1] === 'No') {
          newFullAnswers['What gender do you identify as?'] = answer[0]
        } else if (answer[1] === 'Prefer not to say') {
          newFullAnswers['What gender do you identify as?'] = answer[1]
        }
      } else {
        newFullAnswers[title] = answer
      }

      this.saveApiResult(apiName, value)
      this.getQuestionBranch(title, answer, value, newFullAnswers)
    }

    window.scrollTo(0, 0)
  }

  saveApiResult = (apiName, value, clean) => {
    const newAnswers = clean
      ? { demographic: {}, mindTrack: {}, dq5: {} }
      : { ...this.state.answers }

    if (typeof value === 'object' && apiName === 'sex') {
      // level question
      newAnswers[this.props.data.group_id][apiName] = value[0]
      if (value[1] === 'no') {
        // if "no" then we're using the same gender as sex
        newAnswers[this.props.data.group_id].gender = value[0]
      } else if (value[1] === 'undisclosed') {
        // if "prefer not to say" then we're using "undisclosed" as gender
        newAnswers[this.props.data.group_id].gender = value[1]
      } else if (value[1] === 'yes') {
        // if "prefer not to say" then we're using "undisclosed" as gender
        newAnswers[this.props.data.group_id].gender = 'other'
      }
    } else {
      newAnswers[this.props.data.group_id][apiName] = value
    }
    this.setState({ answers: newAnswers })
  }

  getQuestionBranch = (title, answer, value, newFullAnswers) => {
    const { fullAnswers, step } = this.state
    const { length } = this.props.data.questions
    // check needed questions to make step unusual (skip questions)
    if (title === 'What is your (biological) sex?') {
      this.setState({ step: step + 1, fullAnswers: newFullAnswers })
    } else if (
      this.props.branch === 'male' &&
      title === 'Are you in a relationship?' &&
      answer === 'No'
    ) {
      this.setState({ step: 11, fullAnswers: newFullAnswers })
    } else if (title === 'Are you currently in paid work?' && answer === 'No') {
      this.setState({ step: 2, fullAnswers: newFullAnswers })
    } else if (title === 'Do you live with your partner?' && answer === 'No') {
      this.setState({ step: 11, fullAnswers: newFullAnswers })
    } else {
      if (step < length - 1) {
        this.setState({ step: step + 1, fullAnswers: newFullAnswers })
      } else {
        this.setState({ step: 'initial' })
        this.props.onSurveyFinish(fullAnswers)
      }
    }
  }

  handleAgreed = (val) => {
    this.setState({ agreed: val, showInfo: true })
  }

  handleDisagree = () => {
    this.setState({ agreed: false, showInfo: false })
  }

  handleInfoAnswer = (val) => {
    if (val) {
      // clicked I agree, submit the data and go to the rec pac
      this.setState({ agreed: val }, this.handleFinishClick)
    } else {
      this.setState({ agreed: val, showInfo: false })
    }
  }

  handleFinishClick = async () => {
    await this.submitResults()

    this.props.navigate(getLink(this.props.routePath, '/recommendations'), {
      state: { responseData: this.state.responseData }
    })
  }

  submitResults = async () => {
    this.setState({ loading: true })

    const { source, medium, campaign } = this.props

    const surveysEndpoint =
      'https://gn3xqh0x8l.execute-api.ap-southeast-2.amazonaws.com/latest/surveys'

    let resource = {}

    if (this.props.fund) {
      resource = {
        result: this.state.answers,
        consentToStudy: this.state.agreed,
        fund: this.props.fund
      }
    } else {
      resource = {
        result: this.state.answers,
        consentToStudy: this.state.agreed
      }
    }

    // move covid fields up
    resource.result.mindTrack = {
      ...resource.result.mindTrack,
      ...resource.result.mindTrack.covid
    }

    // utm tags

    if (source && typeof source === 'string') {
      resource.source = source
    }

    if (medium && typeof medium === 'string') {
      resource.medium = medium
    }

    if (campaign && typeof campaign === 'string') {
      resource.campaign = campaign
    }

    // remove covid object
    /*if ('covid' in resource.result.mindTrack){
      delete resource.result.mindTrack.covid
    }
    console.log(resource.result.mindTrack.covid)

    if ('covidEmploymentStatus' in resource.result.mindTrack){
      delete resource.result.mindTrack.covidEmploymentStatus
    }
    if ('covidFinancialHealth' in resource.result.mindTrack){
      delete resource.result.mindTrack.covidFinancialHealth
    }
    if ('covidMentalHealth' in resource.result.mindTrack){
      delete resource.result.mindTrack.covidMentalHealth
    }
    if ('covidPhysicalHealth' in resource.result.mindTrack){
      delete resource.result.mindTrack.covidPhysicalHealth
    }
    delete resource.result.mindTrack.covid
    */
    const res = await axios.post(surveysEndpoint, resource)

    this.setState({ loading: false, responseData: res.data })
  }

  handleBackClick = () => {
    const { step, fullAnswers } = this.state

    if (step === 0) {
      this.setState({ step: 'initial' })
    } else if (typeof step === 'number' && step >= 1) {
      this.setState((prevState) => ({ step: prevState.step - 1 }))

      // BRANCHING

      // FEMALE
      if (
        this.props.branch === 'female' &&
        this.props.data.questions[step].title ===
          'How satisfied are you with your employment opportunities? The more satisfied you are, the higher the number that you pick'
      ) {
        if (
          fullAnswers['Are you currently in paid work?'] &&
          fullAnswers['Are you currently in paid work?'] === 'No'
        ) {
          this.setState({ step: 0 })
        } else {
          this.setState({ step: 1 })
        }
      }

      // MALE
      if (
        this.props.branch === 'male' &&
        this.props.data.questions[step].title ===
          'How much do you agree with the statement: "I don\'t have anyone I can confide in". The more you agree, the higher the number you pick'
      ) {
        if (
          fullAnswers['Are you in a relationship?'] &&
          fullAnswers['Are you in a relationship?'] === 'No'
        ) {
          this.setState({ step: 8 })
        } else {
          this.setState({ step: 10 })
        }
      }
    } else if (step === 'initial' || step === 'result') {
      this.setState({ step: this.props.onCheckBack() })
    }

    window.scrollTo(0, 0)
  }

  getIE11LongQuestion = () => {
    const { data } = this.props
    const { step } = this.state
    if (
      data &&
      step &&
      data.questions &&
      data.questions[step] &&
      (data.questions[step].title ===
        'What kind of impact has COVID-19 had on the following areas of your life, or what impact do you think it might have over the next 12 months? The more negative an impact, the lower a number you select' ||
        data.questions[step].title ===
          'Have you ever sought help for a mental health problem from any of these sources? [Click all that apply]')
    ) {
      return true
    } else {
      return false
    }
  }

  render () {
    const { data, welcomeImageWidth } = this.props
    const { step, loading, agreed, answers, showInfo } = this.state
    // used for IE11 since some questions are extremely tall
    // so we can manipulate overflow properly
    const isTall = this.getIE11LongQuestion()

    return (
      <Main step={step} showInfo={showInfo} isTall={isTall}>
        {typeof step === 'number' && (
          <StepWrapper>
            <Step current={step + 1} max={data.questions.length} />
          </StepWrapper>
        )}
        {showInfo && (
          <Info
            onAgreed={this.handleInfoAnswer}
            isLoading={loading}
            fund={this.props.fund}
          />
        )}
        <HeaderWrapper>
          <Header
            dataStep={this.props.dataStep}
            type={getHeaderType(answers, step)}
            onBackClick={this.handleBackClick}
          />
        </HeaderWrapper>
        {step === 'initial' && (
          <Welcome
            data={data}
            dataStep={this.props.dataStep}
            onBeginClick={this.handleBeginClick}
            welcomeImageWidth={welcomeImageWidth}
          />
        )}
        {typeof step === 'number' && (
          <QuestionsWrapper>
            <Questions
              data={data.questions[step]}
              onQuestionAnswered={this.handleQuestionAnswered}
            />
          </QuestionsWrapper>
        )}
        {step === 'result' && (
          <Result
            isLoading={loading}
            data={data}
            onFinishClick={this.handleFinishClick}
            agreed={agreed}
            onAgreed={this.handleAgreed}
            onDisagree={this.handleDisagree}
          />
        )}
      </Main>
    )
  }
}

export default Survey
