import { StyleSheet } from '@react-pdf/renderer'

const sharedStyles = StyleSheet.create({
  title: {
    color: '#f49188',
    fontSize: 30,
    fontFamily: 'Proxima Nova Bold',
    marginBottom: 7
  },
  subtitle: {
    color: '#42567A',
    fontSize: 23,
    fontFamily: 'Proxima Nova Bold',
    marginBottom: 4
  },
  smallTitle: {
    minHeight: 25,
    color: '#42567A',
    fontSize: 10,
    fontFamily: 'Proxima Nova Bold',
    marginBottom: 5
  },
  text: {
    color: '#77787B',
    fontSize: 10,
    fontFamily: 'Proxima Nova Regular',
    lineHeight: 1.8
  },
  textDensed: {
    color: '#77787B',
    fontSize: 8,
    fontFamily: 'Proxima Nova Regular',
    lineHeight: 1.5,
    flex: 1,
    marginBottom: 7
  }
})

export default sharedStyles
