import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  @media only screen and (min-width: 992px) {
    max-width: 880px;
    margin: 0 auto;
  }
`

const Title = styled.h2`
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
  font-family: 'Proxima Nova Bold';
  color: ${(props) => props.theme.colors.primary};

  @media only screen and (min-width: 992px) {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1px;
  }
`

const HugeTitle = ({ id, title }) => (
  <Wrapper>
    <Title id={id}>{title}</Title>
  </Wrapper>
)

export default HugeTitle
