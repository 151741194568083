import React from 'react'
import styled from 'styled-components/macro'
import ReactSlider from 'react-slider'

import check from './check.svg'

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 40px;
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`

const StyledThumb = styled.div`
  width: 38px;
  height: 38px;
  line-height: 38px;
  border: 0;
  background: none;
  border-radius: 50%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: grab;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`

const Check = styled.img`
  width: 14px;
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  border: 2px solid ${(props) => props.theme.colors.primary};
  height: 6px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: ${(props) =>
    props.index === 1 ? '#fff' : props.theme.colors.primary};
  border-radius: 999px;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const Numbers = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  padding-left: 17px;
  padding-right: ${(props) => (props.max === 10 ? '10px' : '15px')};
`

const Number = styled.h5`
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`

const generateNumbers = (max = 10, value) => {
  const arr = Array.from(Array(max), (e, i) => i + 1)

  const elements = arr.map((item) => (
    <Number selected={item === parseInt(value)} key={`num-${item}`}>
      {item}
    </Number>
  ))

  return elements
}

const AdditionPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 32px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 64px;
  }
`

const Addition = styled.p`
  font-size: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 2px;
  color: ${(props) => props.theme.colors.primaryAccent};
  text-align: center;
  margin-bottom: 20px;
`

const Slider = ({
  title,
  max,
  left,
  right,
  onQuestionAnswered,
  apiName,
  value
}) => {
  const handleAnswer = (sliderValue) => {
    onQuestionAnswered(apiName, sliderValue)
  }

  return (
    <SliderWrapper>
      {title && <Title>{title}</Title>}
      <StyledSlider
        value={value}
        min={1}
        max={max}
        renderTrack={Track}
        renderThumb={(props, state) => (
          <StyledThumb onDragStart={(e) => e.preventDefault()} {...props}>
            <Check src={check} alt='Check' />
          </StyledThumb>
        )}
        onChange={handleAnswer}
      />
      <Numbers max={max}>{generateNumbers(max, value)}</Numbers>
      <AdditionPanel>
        <Addition>{left}</Addition>
        <Addition>{right}</Addition>
      </AdditionPanel>
    </SliderWrapper>
  )
}

export default Slider
