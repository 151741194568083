import React from 'react'
import styled from 'styled-components/macro'

import { Title, Text } from './Components'
import talLogo from './icons/tal-g.svg'
import usydLogo from './icons/usyd.svg'

const Component = styled.div`
  padding: 32px 24px;

  @media only screen and (min-width: 768px) {
    padding: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 992px) {
    padding: 96px;
  }
`

const Wrapper = styled.div`
  max-width: 880px;
  width: 100%;
`

const Content = styled.div`
  & > div:last-of-type > div {
    margin-top: 32px;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;

    & > div:last-of-type {
      margin-left: 16px;
      & > div {
        margin-top: 0;
      }
    }
  }
  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    & > div:last-of-type {
      margin-left: 40px;
    }
  }

  /* ie11 */
  @media only screen and (min-width: 992px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    & > div:last-of-type {
      margin-left: 80px;
    }
  }
`

const LogoWrapper = styled.div`
  height: 112px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`

const Logo = styled.img`
  width: ${(props) => `${props.width}px`};
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media only screen and (min-width: 768px) {
    max-width: 400px;
  }

  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    max-width: none;
  }
`

const Behind = () => (
  <Component id='behind'>
    <Wrapper>
      <Title>Who’s behind Headlight?</Title>
      <Content>
        <Item>
          <LogoWrapper>
            <Logo src={talLogo} alt='TAL' width={100} />
          </LogoWrapper>
          <Text>
            Whether it's sharing expert health insights or providing tools that
            simplify the way you look after yourself, TAL's goal is to help
            Australians live as healthy and full a life as possible.
          </Text>
        </Item>
        <Item>
          <LogoWrapper>
            <Logo src={usydLogo} alt='The University of Sydney' width={234} />
          </LogoWrapper>
          <Text>
            As the research partner behind Headlight, The University of Sydney
            Brain and Mind Centre has consulted on the Headlight tools’
            development, contributing their expertise and research in
            psychological distress and mental health risk factors.
          </Text>
        </Item>
      </Content>
    </Wrapper>
  </Component>
)

export default Behind
