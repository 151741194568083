import React, { useState } from 'react'
import styled from 'styled-components/macro'

import iconClose from './x.svg'
import iconQuestion from './question.svg'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
`

const Button = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${(props) => props.theme.colors.primaryAccent};
  border: 50%;

  & > img {
    width: 10px;
  }
`

const Icon = styled.img``

const Info = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primaryAccent};
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  max-width: 482px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-100% - 35px);

  &:before {
    content: '';
    width: 10px;
    height: 15px;
    border-radius: 2px;
    transform: rotate(45deg) translateX(-50%);
    background-color: ${(props) => props.theme.colors.primaryAccent};
    position: absolute;
    top: 0px;
    left: 50%;
  }
`

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: #fff;
`

const FloatingInfo = ({ text }) => {
  const [opened, setOpened] = useState(false)

  const handleOpen = () => {
    setOpened(!opened)
  }

  return (
    <Component>
      {opened && (
        <Info>
          <CloseButton onClick={handleOpen}>
            <Icon src={iconClose} alt='Info' />
          </CloseButton>
          <Text>{text}</Text>
        </Info>
      )}
      <Button onClick={handleOpen}>
        <Icon src={iconQuestion} alt='Info' />
      </Button>
    </Component>
  )
}

export default FloatingInfo
