import React from 'react'
import {
  Page,
  StyleSheet,
  View,
  Text,
  Image,
  Link as PdfLink
} from '@react-pdf/renderer'

import headlight from './img/headlight-dark.png'
import book from './img/book.png'
import sharedStyles from './sharedStyles'

import Footer from './Footer'
import Link from './Link'

const pageProps = {
  size: 'A4',
  wrap: false
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F9F7F7',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Regular'
  },
  content: {
    padding: '0 84',
    flex: 1,
    justifyContent: 'center'
  },
  innerContent: {},
  signup: {
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 30
  },
  book: {
    width: 40,
    marginBottom: 20
  },
  link: {
    marginTop: 20,
    color: '#F49188',
    fontSize: 15,
    fontFamily: 'Proxima Nova Bold'
  },
  logos: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 90,
    alignItems: 'center',
    justifyContent: 'center'
  },
  tal: {
    width: 70
  },
  headlight: {
    width: 163,
    marginLeft: 34,
    marginRight: 34
  },
  usyd: {
    width: 77
  }
})

const Final = ({ headToHealth }) => {
  return (
    <Page {...pageProps} style={styles.page}>
      <View style={styles.content}>
        <View style={styles.innerContent}>
          <Text style={sharedStyles.subtitle}>{headToHealth.title}</Text>
          <Text style={sharedStyles.text}>{headToHealth.text}</Text>
          <Link
            bigger
            url='https://headtohealth.gov.au/?beacn_id=1590629783769'
            label='Head to Health'
          />
          <View style={styles.signup}>
            <Image src={book} style={styles.book} />
            <Text style={sharedStyles.text}>
              By anonymously contributing your data to the University of
              Sydney’s research study, you are helping to improve our
              understanding of mental health in the wider Australian community.
            </Text>
            <PdfLink
              src='https://redcap.sydney.edu.au/surveys/?s=8X7CAYMP3D'
              label='Head to Health'
              style={styles.link}
            >
              Sign up for research updates
            </PdfLink>
          </View>
          <View style={styles.logos}>
            <View style={styles.headlight}>
              <Image src={headlight} />
            </View>
          </View>
        </View>
      </View>
      <Footer />
    </Page>
  )
}

export default Final
