import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'

const Component = styled.div`
  min-height: 100vh;
  background: ${(props) => props.theme.colors.primaryAccent};
  padding: 24px;
`

export const Logo = styled.h3`
  font-family: 'Proxima Nova Semibold';
  width: 100%;
  padding: 24px 0;
  margin-bottom: 35px;
  text-align: center;
  color: #fff;
  letter-spacing: 5px;
  font-size: 26px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
`

export const Content = styled.div`
  @media only screen and (min-width: 768px) {
    max-width: 679px;
    margin: 0 auto;
  }
`

export const Header = styled.h2`
  color: #fff;
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
`

const Subheader = styled.h3`
  font-size: 18px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyLight};
  margin-bottom: 20px;
  font-weight: bold;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyLight};
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 60px;
  }
`

const Separator = styled.div`
  width: 200px;
  height: 2px;
  border-top: 1px solid ${(props) => props.theme.colors.greyLight};
  margin-bottom: 10px;
`

const Addition = styled.p`
  font-size: 11px;
  color: ${(props) => props.theme.colors.greyLight};
`

const Terms = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <Component>
      <Logo>Headlight</Logo>
      <Content>
        <Header>HEADLIGHT MENTAL HEALTH CAMPAIGN</Header>
        <Text>Member Acknowledgement:</Text>
        <Text>
          <em>I confirm that I have read and agree to the Terms of Use.</em>
        </Text>
        <Header>HEADLIGHT TERMS OF USE</Header>
        <Subheader>Headlight overview</Subheader>
        <Text>
        HEADLIGHT is an online mental health screening tool that utilises both the Distress Questionnaire (DQ5)<sup>1</sup> and associated algorithm, developed by the Australian National University and the University of Sydney Brain and Mind Centre respectively. HEADLIGHT identifies a participant’s mental health risk factors based on their responses to the questionnaire and provides suggested resources and actions for the participant to improve their wellbeing.
        </Text>

        <Subheader>Parties involved with the Headlight initiative</Subheader>
        <Text>
          The HEADLIGHT tool is owned and funded by TAL Services Limited ABN 60 076 105 130 (“TAL”) and was developed in partnership with the University of Sydney Brain and Mind Centre (“Brain and Mind Centre”) for the purpose of making the tool available to a wide range of participants, to offer the participants the opportunity to better understand their mental health.
        </Text>
        <Text>
          TAL is responsible for Headlight website through which the HEADLIGHT tool can be accessed.
        </Text>
        <Text>
          Your superannuation fund is making the HEADLIGHT tool available to you as a member of the fund. Your superannuation fund has not been involved in the development of the HEADLIGHT tool and is not responsible for any of the HEADLIGHT tool’s recommendations (including suggested resources) made in response to a participant using it.
        </Text>

        <Subheader>No collection of personal information</Subheader>
        <Text>
          You will not be identified through using the HEADLIGHT tool and your responses to the questions will be de-identified and remain anonymous. TAL and your superannuation fund will not see any individual responses to the questions and will not receive any data that identifies any member who participated in the HEADLIGHT initiative.
        </Text>

        <Subheader>Use of de-identified data</Subheader>
        <Text>
          A third party supplier will manage the anonymous/non-identifiable data received through the HEADLIGHT tool.  This anonymous/non-identifiable data will only be shared with TAL and your superannuation fund. 
        </Text>
        <Text>
          If you tick the box at the end of the questionnaire saying that you consent to it, then your anonymous responses to the questions will also be sent to the Brain and Mind Centre to be used for research and analysis purposes. 
        </Text>
        <Text>
          The Brain and Mind Centre will use the de-identified data collected to prepare reports which contain the overall aggregated results of the research and analysis done by them. Reports prepared by the Brain and Mind Centre may be shared with TAL and your superannuation fund. TAL and your superannuation fund will not receive any data relating to individual members who participated in the HEADLIGHT initiative.
        </Text>
        <Text>
          Use of the HEADLIGHT tool and participation in the research study is voluntary.
        </Text>

        <Subheader>HEADLIGHT is not a substitute for medical advice</Subheader>
        <Text>
          The HEADLIGHT tool aims to assist the participant to better understand their mental health and open pathways for solutions to possible underlying issues or preventative measures. It is not intended to be a substitute for professional medical advice, diagnosis or treatment. You should seek the advice of an appropriately qualified healthcare professional before making any decisions about your own circumstances. 
        </Text>
        <Text>
          To the maximum extent permitted by law, your superannuation fund, TAL, and the Brain and Mind Centre: (a) exclude all warranties, guarantees and conditions (whether express, implied or statutory) in relation to the HEADLIGHT tool; and (b) do not accept any responsibility or liability for any injury, loss or damage, however caused (and whether direct, indirect, consequential or economic) which may be directly or indirectly suffered in connection with the HEADLIGHT initiative, including (without limitation) any use of or reliance on the HEADLIGHT tool or any suggested resources provided by the HEADLIGHT tool.  
        </Text>

        <Subheader>
          By participating in this initiative, you agree to these Terms of Use.
        </Subheader>

        <Separator />
        <Addition>
        <sup>1</sup> Batterham, P. J., Sunderland, M., Carragher, N., Calear, A. L.,
          Mackinnon, A. J., Slade, T. (2016). The Distress Questionnaire-5:
          Population screener for psychological distress was more accurate than
          the K6/K10. <em>Journal of Clinical Epidemiology, 71</em>, 35-42.
        </Addition>
      </Content>
    </Component>
  )
}

export default Terms
