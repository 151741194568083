import React from 'react'
import { View, Image, Text } from '@react-pdf/renderer'

import headlightInverted from './img/headlight-red.png'
import headlight from './img/headlight-dark.png'

const styles = {
  headerWrapper: {
    padding: '25 84',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  header: {
    color: '#f49188',
    fontSize: 20,
    fontFamily: 'Proxima Nova Bold'
  },
  imageWrapper: {
    width: 100
  }
}

const Header = ({ inverted }) => (
  <View style={styles.headerWrapper} fixed>
    <View style={styles.imageWrapper}>
      <Image src={inverted ? headlightInverted : headlight} />
    </View>
    <Text style={styles.header}>Headlight</Text>
  </View>
)

export default Header
