import styled from 'styled-components/macro'

const Wrapper = styled.div`
  min-height: 49px;
  border-radius: 6px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  padding: 6px 1.5rem;
  justify-content: space-between;
  margin: ${(props) => (props.space ? '18px 0' : '0')};
  background: #fff;

  @media only screen and (min-width: 768px) {
    padding: 32px 88px;
  }

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const Label = styled.p`
  font-family: 'Proxima Nova Bold';
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.primaryAccent};
  letter-spacing: -0.5px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
`

const Arrow = styled.img`
  transform: ${(props) => (props.opened ? 'rotate(180deg)' : 'none')};
  transition: all 0.25s ease-in;
  width: 28px;
  height: 28px;

  @media only screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

export { Label, Wrapper, Arrow }
