/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'
import Observer from '@researchgate/react-intersection-observer'

import DQ5A from './data/DQ5A'
import HugeTitle from './HugeTitle'
import { Info } from './Components'
import LifeStyleFactors from './LifeStyleFactors'
import linkArrow from '../../components/Card/arrow.svg'
import Arrows from '../../components/PageHeader/Arrows'
import EAP from './EAP'

const Wrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.greyLight};
  overflow-y: hidden;
`

const InnerWrapper = styled.div`
  padding: 58px 24px;

  @media only screen and (min-width: 992px) {
    padding: 133px 168px 183px;
    max-width: 1450px;
    margin: 0 auto;
  }
`

const Link = styled(ReactGA.OutboundLink)`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova Bold';
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: none;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
  margin: 8px 0 40px;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    font-size: 20px;
    margin-top: 3rem;
    margin-bottom: 0;
  }
`

const ArrowIcon = styled.img`
  width: 12x;
  margin-left: 14px;
`

const HealthWrapper = styled.div`
  max-width: 880px;
  margin: 0 auto;
`

const HealthTitle = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 8px;

  @media only screen and (min-width: 992px) {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1px;
  }
`

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Content = ({ data, onTitleIntersection }) => {
  const { dq5, headToHealth, lifestyleFactors, eap } = data.recommendations

  return (
    <Wrapper>
      <InnerWrapper>
        <Observer onChange={onTitleIntersection}>
          <div>
            <HugeTitle id='mental' title='Your mental wellbeing' />
          </div>
        </Observer>
        <DQ5A data={dq5} />
        {eap && <EAP data={eap} />}
        <ArrowsWrapper>
          <Arrows dark />
        </ArrowsWrapper>
        <LifeStyleFactors
          id='lifestyle'
          data={lifestyleFactors}
          pdfData={data}
        />
        <HealthWrapper>
          <HealthTitle id='head'>{headToHealth.title}</HealthTitle>
          <Info>{headToHealth.text}</Info>
          <Link
            rel='noopener noreferrer'
            target='_blank'
            href={headToHealth.link.url}
            eventLabel={headToHealth.link.url}
            to={headToHealth.link.url}
          >
            {headToHealth.link.label}
            <ArrowIcon src={linkArrow} alt='arrow' />
          </Link>
        </HealthWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Content
