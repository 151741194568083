import React, { useState } from 'react'
import styled from 'styled-components/macro'
import image from './finish.svg'

import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Image = styled.img`
  max-width: 160px;
  margin-bottom: 3rem;
`

const Title = styled.h1`
  margin-bottom: 1rem;
  max-width: 596px;
  color: #fff;
`

const Subtitle = styled.p`
  max-width: 596px;
  text-align: center;
  margin-bottom: 2.3125rem;
  font-size: 1.25rem;
  color: #fff;
`

const Panel = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
`

const Info = styled.h5`
  max-width: 200px;
  margin-top: 3px;
  margin-right: 20px;
  color: #fff;
`

const Addition = styled.span`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    filter: brightness(90%);
  }
`

const Popup = styled.div`
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 6px;
  margin-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 7.5px 10px 7.5px;
    border-color: transparent transparent #fff transparent;
  }
`

const PopupText = styled.p`
  font-family: 'Proxima Nova Regular';
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 16px;
`

const Content = ({ title, onFinishClick, isLoading, agreed, onAgreed, onDisagree }) => {
  const [popupShown, setPopupShown] = useState(false)

  const submit = () => {
    if (agreed === null) {
      setPopupShown(true)
    } else {
      setPopupShown(false)
      onFinishClick()
    }
  }

  return (
    <Wrapper>
      <Image src={image} alt={title} />
      <Title>Thank you</Title>
      <Subtitle>
        You have completed the questionnaire. Once you click submit you will
        receive your wellbeing score, see your risk factors and recommended
        therapies.
      </Subtitle>
      <Panel>
        <Info>
          Do you consent for your{' '}
          <Addition onClick={() => onAgreed(true)}>data</Addition> to be used in
          this study
        </Info>
        <Checkbox agreed={agreed} onAgreed={onAgreed} onDisagree={onDisagree} />
      </Panel>
      {popupShown && agreed === null && (
        <Popup>
          <PopupText>Please make a selection</PopupText>
        </Popup>
      )}
      <Button
        isLoading={isLoading}
        disabled={isLoading || agreed === null}
        onClick={submit}
      >
        {!isLoading && 'Submit'}
      </Button>
    </Wrapper>
  )
}

export default Content
