import React from 'react'
import styled from 'styled-components/macro'

const Caption = styled.h6``

const Component = styled.div`
  width: ${props => (props.current / props.max) * 100}%;
  border-top: 8px solid ${props => props.theme.colors.primary};
  display: flex;
  justify-content: flex-end;
`

const Step = ({ current, max }) => (
  <Component current={current} max={max}>
    <Caption>{Math.round((current / max) * 100)}%</Caption>
  </Component>
)

export default Step
