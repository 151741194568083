/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components/macro'

import Content from './Content'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5625rem;
  margin-bottom: 2rem;
  flex: 1;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 0;
  }
`

const Welcome = ({ data, onBeginClick, welcomeImageWidth, dataStep }) => {
  const { image, title, subtitle, group_id, completion } = data
  const length = data.questions.length
  return (
    <Wrapper>
      <Content
        welcomeImageWidth={welcomeImageWidth}
        image={image}
        title={title}
        subtitle={subtitle}
        length={length}
        onBeginClick={onBeginClick}
        groupId={group_id}
        completion={completion}
        dataStep={dataStep}
      />
    </Wrapper>
  )
}

export default Welcome
