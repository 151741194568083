import React from 'react'
import styled from 'styled-components/macro'
import { Link } from '@reach/router'

import Back from '../Back/Back'

const Wrapper = styled.div`
  padding: 1.25rem;
  margin-bottom: ${(props) =>
    props.type === 'messageScreen' ? '1rem' : '4rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* iPhone 7 */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 768px) {
    width: 1000px;
  }
`

const BackWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const Addition = styled.span`
  font-family: 'Proxima Nova Regular';
  margin-left: 10px;
  color: ${(props) => (props.type === 'messageScreen' ? '#fff' : '#000')};
`

const Anonymous = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.type === 'messageScreen' ? '#fff' : '#000')};
`

const Header = ({ type, onBackClick, dataStep }) => (
  <Wrapper type={type}>
    {dataStep === 0 && type === 'messageScreen' ? (
      <>
        <StyledLink to='/'>
          <Back type={type} />
        </StyledLink>
        <Anonymous type={type}>Your answers are anonymous</Anonymous>
      </>
    ) : (
      <>
        <BackWrapper onClick={onBackClick}>
          <Back type={type} />
          <Addition type={type}>Previous</Addition>
        </BackWrapper>
        <Anonymous type={type}>Your answers are anonymous</Anonymous>
      </>
    )}
  </Wrapper>
)

export default Header
