import React, { Component } from 'react'
import ReactGA from 'react-ga'

import Survey from '../../components/Survey/Survey'

class SurveyWrapper extends Component {
  state = {
    // -- male
    // dataStep: 1,
    // branch: 'male',
    // -- female
    // dataStep: 2,
    // branch: 'female',
    dataStep: 0,
    branch: '',
    finished: false
  }

  componentDidMount () {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleSurveyFinish = (fullAnswers) => {
    const { dataStep } = this.state


    if (
      fullAnswers['What is your (biological) sex?'] === 'Male' &&
      dataStep === 0
    ) {
      this.setState({ dataStep: 1, branch: 'male' })
    } else if (
      fullAnswers['What is your (biological) sex?'] === 'Female' &&
      dataStep === 0
    ) {
      this.setState({ dataStep: 2, branch: 'female' })
    } else if (dataStep === 1 || dataStep === 2) {
      this.setState({ dataStep: 3 })
    } else {
      this.setState({ finished: true })
    }
  }

  handleCheckBack = () => {
    const { dataStep, branch, finished } = this.state

    if (dataStep === 1 || dataStep === 2) {
      // going from male or female to demographic
      this.setState({ dataStep: 0 })
      return this.props.data[0].questions.length - 1
    } else if (dataStep === 3) {
      if (finished === true) {
        // going from results to dq5
        this.setState({ dataStep: 3, finished: false })
        return this.props.data[3].questions.length - 1
      } else if (branch === 'male') {
        // going from dq5 to male
        this.setState({ dataStep: 1 })
        return this.props.data[1].questions.length - 1
      } else if (branch === 'female') {
        // going from dq5 to female
        this.setState({ dataStep: 2 })
        return this.props.data[2].questions.length - 1
      }
    }
  }

  render () {
    const { data, navigate, routePath, fund, mediaParams } = this.props
    const { source, medium, campaign } = mediaParams
    const { dataStep, finished, branch } = this.state
    const welcomeImageWidth = 160

    return (
      <Survey
        onCheckBack={this.handleCheckBack}
        branch={branch}
        data={data[dataStep]}
        dataStep={dataStep}
        navigate={navigate}
        finished={finished}
        welcomeImageWidth={welcomeImageWidth}
        onSurveyFinish={this.handleSurveyFinish}
        routePath={routePath}
        fund={fund}
        source={source}
        medium={medium}
        campaign={campaign}
      />
    )
  }
}

export default SurveyWrapper
