import React from 'react'
import styled from 'styled-components/macro'

import Button from '../Button/Button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Image = styled.img`
  max-width: ${(props) => props.welcomeImageWidth}px;
  margin-bottom: 3rem;
`

const Title = styled.h1`
  margin-bottom: 1rem;
  max-width: 596px;
  color: #fff;
  text-align: center;
`

const Questions = styled.h4`
  margin-bottom: 0.5rem;
  max-width: 596px;
  color: #fff;
`

const Subtitle = styled.p`
  max-width: 596px;
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  margin: 1rem 0 2rem 0;
`

const Completion = styled(Subtitle)`
  margin: 0 0 2rem 0;
  font-size: 14px;
  font-family: 'Proxima Nova Bold';
`

// const Completed = styled.h4`
//   margin-bottom: 0.75rem;
// `

const getStep = (dataStep) => {
  if (dataStep === 0) {
    return 1
  } else if (dataStep === 1 || dataStep === 2) {
    return 2
  } else {
    return 3
  }
}

const Content = ({
  image,
  title,
  subtitle,
  length,
  onBeginClick,
  welcomeImageWidth,
  groupId,
  completion,
  dataStep
}) => (
  <Wrapper>
    <Image src={image} alt={title} welcomeImageWidth={welcomeImageWidth} />
    <Title>{title}</Title>
    <Questions>
      {/* dataStep is 0, 2, 3 for female */}
      {/* 0, 1, 3 for male */}
      Section {getStep(dataStep)} of 3 ({length} questions)
    </Questions>
    <Subtitle>{subtitle}</Subtitle>
    {completion && <Completion>{completion}</Completion>}
    {/* <Completed>0/25 questions completed</Completed> */}
    <Button onClick={onBeginClick} white>
      {groupId === 'demographic' ? "Let's begin" : "Let's continue"}
    </Button>
  </Wrapper>
)

export default Content
