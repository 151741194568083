import React from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'

import { Text } from './Components'
import book from './icons/book.svg'

const Component = styled.div`
  background-color: ${(props) => props.theme.colors.primaryAlt};
  padding: 32px 24px;

  @media only screen and (min-width: 768px) {
    padding: 48px;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 992px) {
    padding: 130px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    max-width: 880px;
  }

  /* ie11 */
  @media only screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    width: 100%;
  }
`

const Icon = styled.img`
  margin-bottom: 38px;

  @media only screen and (min-width: 768px) {
    height: 93px;
  }
`

const Link = styled(ReactGA.OutboundLink)`
  font-family: 'Proxima Nova Bold';
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  margin-top: 1rem;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: none;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (min-width: 768px) {
    display: inline-block;
    width: auto;
    margin: 32px 0 0 0;
  }
`

const Research = ({ noIcon, text, link, url }) => (
  <Component id='research'>
    <Container>
      {!noIcon && <Icon src={book} alt='Headlight' />}
      <Text>
        {text ||
          'By using Headlight you can also elect to assist The University of Sydney by anonymously contributing your data to their research study, aimed at understanding the presence of certain risk factors in the wider population.'}
      </Text>
      <Link
        href={
          url || 'https://journals.sagepub.com/doi/10.1177/0004867417704506'
        }
        rel='noopener noreferrer'
        target='_blank'
        eventLabel={
          url || 'https://journals.sagepub.com/doi/10.1177/0004867417704506'
        }
        to={url || 'https://journals.sagepub.com/doi/10.1177/0004867417704506'}
      >
        {link || 'Explore the research'}
      </Link>
    </Container>
  </Component>
)

export default Research
