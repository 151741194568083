import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Link } from '@reach/router'
import ReactGA from 'react-ga'
import Observer from '@researchgate/react-intersection-observer'

import PageHeader from '../../components/PageHeader/PageHeader'
import Content from './Content'
import Research from '../Home/Research'
import PageFooter from '../../components/PageFooter/PageFooter'
import getLink from '../../components/utils/routeNavigation'

const Wrapper = styled.div`
  position: relative;
`

const FinishFirst = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Assistance = styled.div`
  padding: 13px 24px;
  position: fixed;
  width: 100%;
  max-width: 327px;
  bottom: 18px;
  border-left: 4px solid ${(props) => props.theme.colors.primaryAccent};
  background-color: ${(props) => props.theme.colors.success};
  z-index: 1;
  box-shadow: 0px 12px 24px rgba(41, 43, 50, 0.25);
  left: 50%;
  transform: translateX(-50%);
  display: ${(props) => (props.topVisible ? 'none' : 'flex')};
  justify-content: center;

  @media only screen and (min-width: 992px) {
    display: flex;
    transform: none;
    max-width: 288px;
    left: auto;
    bottom: auto;
    right: 16px;
    top: 150px;
  }
`

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.primaryAccent};

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`

const StyledLink = styled(Link)``

const Recommendations = ({ location, routePath, fund }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const [headerVisible, setHeaderVisible] = useState(false)
  const [titleVisible, setTitleVisible] = useState(false)

  const handleHeaderIntersection = (e) => {
    const { isIntersecting } = e

    if (headerVisible !== isIntersecting) {
      setHeaderVisible(isIntersecting)
    }
  }

  const handleTitleIntersection = (e) => {
    const { isIntersecting } = e

    if (titleVisible !== isIntersecting) {
      setTitleVisible(isIntersecting)
    }
  }

  const [locationState] = useState(location.state)

  if (locationState && locationState.responseData) {
    return (
      <Wrapper>
        {/* assistance should go after pageheader + header of mental wellbeing */}
        <Observer onChange={handleHeaderIntersection}>
          <div>
            <PageHeader
              dq5Inverted={locationState.responseData.dq5Inverted}
              information={locationState.responseData.information}
              variation='recommendation'
              routePath={routePath}
            />
          </div>
        </Observer>
        <Content
          data={locationState.responseData}
          onTitleIntersection={handleTitleIntersection}
        />
        {locationState.responseData.consentToStudy && (
          <Research
            text='By anonymously contributing your data to the University of Sydney’s research study, you are helping to improve our understanding of mental health in the wider Australian community.'
            url='https://redcap.sydney.edu.au/surveys/?s=8X7CAYMP3D'
            link='Sign up for research updates'
          />
        )}
        <PageFooter routePath={routePath} fund={fund} biggerSpaceMobile />
        <Assistance topVisible={headerVisible || titleVisible}>
          <Text>
            If you require urgent assistance please contact Lifeline on{' '}
            <strong>13 11 14</strong>
          </Text>
        </Assistance>
      </Wrapper>
    )
  } else {
    return (
      <FinishFirst>
        <h1>
          Please finish the survey first{' '}
          <span role='img' aria-label='survey'>
            📄
          </span>
        </h1>
        <StyledLink to={getLink(routePath, '/survey')}>
          <h4>Go to survey</h4>
        </StyledLink>
        <StyledLink to={getLink(routePath, '/')}>
          <h4>Go back home</h4>
        </StyledLink>
      </FinishFirst>
    )
  }
}

export default Recommendations
