import React from 'react'

import { View, Text } from '@react-pdf/renderer'

const styles = {
  footer: {
    padding: '24 84',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  copyright: {
    color: '#42567A',
    fontSize: 8,
    fontFamily: 'Proxima Nova Regular'
  },
  pages: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  current: {
    color: '#F49188',
    fontSize: 8,
    fontFamily: 'Proxima Nova Bold'
  },
  delimeter: {
    color: '#77787B',
    margin: '0 5',
    opacity: 0.2,
    fontSize: 20,
    fontFamily: 'Proxima Nova Regular'
  },
  total: { color: '#42567A', fontSize: 8, fontFamily: 'Proxima Nova Regular' }
}

const getDate = () => new Date().getFullYear()

const Footer = () => (
  <View style={styles.footer} fixed>
    <Text style={styles.copyright}>Copyright Headlight {getDate()}</Text>
    <View style={styles.pages}>
      <View
        style={styles.current}
        render={({ pageNumber }) => (
          <Text style={styles.current}>{pageNumber - 1}</Text>
        )}
      >
        <Text>1</Text>
      </View>
      <Text style={styles.delimeter}>/</Text>
      <View
        style={styles.total}
        render={({ totalPages }) => (
          <Text style={styles.total}>{totalPages - 1}</Text>
        )}
      >
        <Text>3</Text>
      </View>
    </View>
  </View>
)

export default Footer
