import React from 'react'
import styled, { css } from 'styled-components/macro'

import Loader from '../Loader/Loader'

const RegularButton = styled.button`
  position: relative;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  border-radius: 4px;
  background: ${(props) =>
    props.isLoading
      ? props.theme.colors.secondaryAlt
      : props.theme.colors.primary};
  color: ${(props) =>
    props.isLoading ? '#fff' : props.theme.colors.primaryAccent};
  border: 0;

  ${(props) =>
    props.white &&
    css`
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
    `}

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  @media only screen and (min-width: 992px) {
    max-width: 208px;
  }
`

const HomeButton = styled(RegularButton)`
  margin-bottom: 1rem;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  font-family: 'Proxima Nova Bold';

  @media only screen and (min-width: 992px) {
    margin-bottom: 20px;
    max-width: 380px;
    padding: 16px 48px;
    font-size: 20px;
    line-height: 32px;
  }
`

const Button = ({ children, onClick, home, isLoading, white }) =>
  home ? (
    <HomeButton onClick={onClick}>{children}</HomeButton>
  ) : (
    <RegularButton
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
      white={white}
    >
      {children}
      {isLoading && <Loader />}
    </RegularButton>
  )

export default Button
