import React, { Component } from 'react'
import styled from 'styled-components/macro'
import checkIcon from './check.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h5 {
    color: #fff;
  }

  &:not(:last-of-type) {
    margin-bottom: 3px;
  }

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }
`

const Checkmark = styled.div`
  background-color: ${(props) => props.theme.colors.secondaryAlt};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border-radius: 6px;
  border: 1px solid silver;
`

const Icon = styled.img`
  width: 12px;
`

class Checkbox extends Component {
  state = {
    toggle: 'no'
  }

  handleClick = (val) => () => {
    if (val) {
      // agreed
      this.props.onAgreed(val)
    } else {
      this.props.onDisagree()
    }
  }

  render () {
    const { agreed } = this.props
    return (
      <Wrapper>
        <Button role='button' onClick={this.handleClick(true)}>
          <h5>Yes</h5>{' '}
          <Checkmark>{agreed === true && <Icon src={checkIcon} />}</Checkmark>
        </Button>
        <Button role='button' onClick={this.handleClick(false)}>
          <h5>No</h5>{' '}
          <Checkmark>{agreed === false && <Icon src={checkIcon} />}</Checkmark>
        </Button>
      </Wrapper>
    )
  }
}

export default Checkbox
