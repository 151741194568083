import React from 'react'
import styled from 'styled-components/macro'

const Component = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const Text = styled.p`
  font-family: 'Proxima Nova Regular', sans-serif;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryAccent};
  font-size: 14px;
  line-height: 16px;
  margin-top: -2px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    margin-top: 3px;
  }
`

const Box = styled.div`
  flex-shrink: 0;
  position: relative;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.colors.primaryAccent
        : props.theme.colors.primary};
  background: ${(props) =>
    props.checked ? props.theme.colors.primary : '#fff'};
  margin-right: 8px;
`

const CheckIcon = styled.div`
  &:after {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: alternativeCheck;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: 10px;
    width: 6px;
    transform-origin: left top;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    content: '';
    left: 4px;
    top: 10px;
    position: absolute;
  }

  @keyframes alternativeCheck {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 6px;
      opacity: 1;
    }
    40% {
      height: 10px;
      width: 6px;
      opacity: 1;
    }
    100% {
      height: 10px;
      width: 6px;
      opacity: 1;
    }
  }
`

const SmallCheckbox = ({ children, accepted, onClick }) => (
  <Component>
    <Box onClick={onClick} checked={accepted}>
      {accepted && <CheckIcon />}
    </Box>
    <Text>{children}</Text>
  </Component>
)

export default SmallCheckbox
