import styled from 'styled-components/macro'

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 24px;

  @media only screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 32px;
  }
`

const Info = styled.p`
  font-family: 'Proxima Nova Regular';
  color: ${(props) => props.theme.colors.greyDark};
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;

  @media only screen and (min-width: 992px) {
    font-size: 24px;
    margin-bottom: 48px;
  }
`

const Subtitle = styled.h5`
  font-family: 'Proxima Nova Bold';
  font-size: 24px;
  line-height: 1.25;
  margin: 2rem 0;
  color: ${(props) => props.theme.colors.primaryAccent};

  @media only screen and (min-width: 992px) {
    font-size: 36px;
  }
`

export { Title, Info, Subtitle }
