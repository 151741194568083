import demographic from './demographic.svg'
import about from './about.svg'
import dq5 from './dq5.svg'

const data = [
  {
    title: 'A bit about you',
    subtitle:
      'In order for Headlight to be able to determine your wellbeing and risk factors we need you to answer a set of questions.',
    completion: 'Total completion time 2-3 minutes',
    image: demographic,
    group_id: 'demographic',
    questions: [
      {
        title: 'What is your (biological) sex?',
        api_name: 'sex',
        question_type: 'levels',
        floatingInfo:
          'Your biological sex may provide different risk factors that are significant to your mental wellbeing.',
        answers: [
          {
            type: 'short',
            title: 'Female',
            value: 'female'
          },
          {
            type: 'short',
            title: 'Male',
            value: 'male'
          }
        ],
        subtitle: 'Do you identify as a different gender?',
        subAnswers: [
          {
            type: 'third',
            title: 'Yes',
            value: 'yes'
          },
          {
            type: 'third',
            title: 'No',
            value: 'no'
          },
          {
            type: 'third',
            title: 'Prefer not to say',
            value: 'undisclosed'
          }
        ]
      },
      {
        title: 'What is your age?',
        api_name: 'age',
        answers: [
          {
            type: 'input',
            min: 18,
            max: 100
          }
        ]
      },
      {
        title: 'Are you of Aboriginal or Torres Strait Islander descent?',
        api_name: 'indigenous',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: 'yes'
          },
          {
            type: 'short',
            title: 'No',
            value: 'no'
          },
          {
            type: 'short',
            title: 'Prefer not to say',
            value: 'undisclosed'
          }
        ]
      },
      {
        title: 'Where were you born?',
        api_name: 'origin',
        answers: [
          {
            type: 'long',
            title: 'Australia',
            value: 'australia'
          },
          {
            type: 'long',
            title: 'Asia, including South Asia',
            value: 'asia'
          },
          {
            type: 'long',
            title: 'Middle East, North Africa',
            value: 'mena'
          },
          {
            type: 'long',
            title: 'Other region',
            value: 'other'
          }
        ]
      },
      {
        title: 'What is the highest level of qualification you have completed?',
        api_name: 'education',
        answers: [
          {
            type: 'long',
            title: 'High school certificate',
            value: 'high school certificate'
          },
          {
            type: 'long',
            title: 'Trade or other Certificate or Diploma',
            value: 'trade or other certificate or diploma'
          },
          {
            type: 'long',
            title: 'Bachelor Degree',
            value: 'bachelor degree'
          },
          {
            type: 'long',
            title: 'Postgraduate Degree',
            value: 'postgraduate degree'
          }
        ]
      },
      {
        title:
          'Have you ever sought help for a mental health problem from any of these sources? [Click all that apply]',
        api_name: 'help',
        question_type: 'multiple',
        answers: [
          {
            title: 'Mental health professional',
            value: 'Mental health professional'
          },
          {
            title: 'Doctor / GP',
            value: 'Doctor / GP'
          },
          {
            title: 'Intimate partner',
            value: 'Intimate partner'
          },
          {
            title: 'Friend',
            value: 'Friend'
          },
          {
            title: 'Parent',
            value: 'Parent'
          },
          {
            title: 'Other relative / family member',
            value: 'Other relative / family member'
          },
          {
            title: 'Telephone helpline',
            value: 'Telephone helpline'
          },
          {
            title: 'Minister / religious leader',
            value: 'Minister / religious leader'
          },
          {
            title: 'Online resources',
            value: 'Online resources',
            addition: 'e.g. Beyond Blue website, online counselling'
          },
          {
            title: 'Mental health apps',
            value: 'Mental health apps',
            addition: 'e.g. myCompass, Smiling Mind'
          },
          {
            title: 'Other',
            value: 'Other'
          },
          {
            title: 'None of the above',
            value: 'None of the above'
          }
        ]
      }
    ]
  },
  {
    title: 'A bit about your life',
    subtitle:
      'There are also certain factors at home, work and in our lifestyle that may influence how likely we are to stay mentally healthy',
    image: about,
    group_id: 'mindTrack',
    questions: [
      {
        title:
          'Do you care for or help anyone on an ongoing basis who has a long-term health condition, is elderly, or has a disability?',
        api_name: 'carer',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How much do you agree with the statement: I have a lot of freedom in deciding when I do my own work?',
        api_name: 'workFreedom',
        answers: [
          {
            type: 'range',
            max: 7,
            left: 'Disagree',
            right: 'Agree'
          }
        ]
      },
      {
        title:
          'In general, how often do you participate in moderate or intensive physical activity for at least 30 mins?',
        api_name: 'physicalActivity',
        answers: [
          {
            type: 'short',
            title: 'Everyday',
            value: 'everyday'
          },
          {
            type: 'short',
            title: '3+ days/wk',
            value: '3+'
          },
          {
            type: 'short',
            title: '3 days/wk',
            value: '3'
          },
          {
            type: 'short',
            title: '1 or 2 days/wk',
            value: '1-2'
          },
          {
            type: 'short',
            title: '<1 day/ wk',
            value: '0'
          }
        ]
      },
      {
        title: 'Do you drink alcohol?',
        api_name: 'alcohol',
        answers: [
          {
            type: 'short',
            title: 'No',
            value: '0'
          },
          {
            type: 'short',
            title: 'Yes, 1-4 times/wk',
            value: '1-4'
          },
          {
            type: 'short',
            title: 'Yes 5+ times/wk',
            value: '5+'
          }
        ]
      },
      {
        title:
          'How satisfied are you with your health? The more satisfied you are, the higher the number you pick',
        api_name: 'physicalHealth',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title:
          'Have you had an episode of poor mental health lasting at least one month in the past two years?',
        api_name: 'mentalHealth',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How satisfied are you with the neighbourhood in which you live? The more satisfied you are, the higher the number you pick',
        api_name: 'neighbourhood',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title: 'Are you in a relationship?',
        api_name: 'inRelationship',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How satisfied are you with your relationship with your partner? The more satisfied you are, the higher the number you pick',
        api_name: 'partner',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title: 'Do you live with your partner?',
        api_name: 'cohabiting',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How satisfied are you with the way household tasks are divided at home?  The more satisfied you are, the higher the number that you pick',
        api_name: 'householdTasks',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title:
          'How much do you agree with the statement: "I don\'t have anyone I can confide in". The more you agree, the higher the number you pick',
        api_name: 'lonely',
        answers: [
          {
            type: 'range',
            max: 7,
            left: 'Disagree',
            right: 'Agree'
          }
        ]
      },
      {
        title:
          'How much do you agree with the statement: "Sometimes I feel I am being pushed around in life". The more you agree, the higher the number you pick',
        api_name: 'bullied',
        answers: [
          {
            type: 'range',
            max: 7,
            left: 'Disagree',
            right: 'Agree'
          }
        ]
      },
      {
        title: 'Do you speak a language other than English at home?',
        api_name: 'secondLanguage',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      }
    ]
  },
  {
    title: 'A bit about your life',
    subtitle:
      'There are also certain factors at home, work and in our lifestyle that may influence how likely we are to stay mentally healthy',
    image: about,
    group_id: 'mindTrack',
    questions: [
      {
        title: 'Are you currently in paid work?',
        api_name: 'employed',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How satisfied are you with the hours you work? The more satisfied you are, the higher the number that you pick',
        api_name: 'workHours',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title:
          'How satisfied are you with your employment opportunities? The more satisfied you are, the higher the number that you pick',
        api_name: 'workOpportunities',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title:
          'How satisfied are you with your health? The more satisfied you are, the higher the number you pick',
        api_name: 'physicalHealth',
        answers: [
          {
            type: 'range',
            max: 10,
            left: 'Dissatisfied',
            right: 'Satisfied'
          }
        ]
      },
      {
        title:
          'Have you had an episode of poor mental health lasting at least one month in the past two years?',
        api_name: 'mentalHealth',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title: 'Are you in a relationship?',
        api_name: 'inRelationship',
        answers: [
          {
            type: 'short',
            title: 'Yes',
            value: true
          },
          {
            type: 'short',
            title: 'No',
            value: false
          }
        ]
      },
      {
        title:
          'How much do you agree with the statement: "Sometimes I feel I am being pushed around in life". The more you agree, the higher the number you pick',
        api_name: 'bullied',
        answers: [
          {
            type: 'range',
            max: 7,
            left: 'Disagree',
            right: 'Agree'
          }
        ]
      },
      {
        title:
          'How much do you agree with the statement: "I don\'t have anyone I can confide in". The more you agree, the higher the number you pick',
        api_name: 'lonely',
        answers: [
          {
            type: 'range',
            max: 7,
            left: 'Disagree',
            right: 'Agree'
          }
        ]
      }
    ]
  },
  {
    title: 'Your current wellbeing',
    subtitle:
      'Our current feelings also influence our risk of poor mental wellbeing in the future',
    image: dq5,
    group_id: 'dq5',
    questions: [
      {
        title: 'In the last 30 days… My worries overwhelmed me',
        api_name: 'overwhelmed',
        answers: [
          {
            type: 'long',
            title: 'Never',
            value: 1
          },
          {
            type: 'long',
            title: 'Rarely',
            value: 2
          },
          {
            type: 'long',
            title: 'Sometimes',
            value: 3
          },
          {
            type: 'long',
            title: 'Often',
            value: 4
          },
          {
            type: 'long',
            title: 'Always',
            value: 5
          }
        ]
      },
      {
        title: 'In the last 30 days… I felt hopeless',
        api_name: 'hopeless',
        answers: [
          {
            type: 'long',
            title: 'Never',
            value: 1
          },
          {
            type: 'long',
            title: 'Rarely',
            value: 2
          },
          {
            type: 'long',
            title: 'Sometimes',
            value: 3
          },
          {
            type: 'long',
            title: 'Often',
            value: 4
          },
          {
            type: 'long',
            title: 'Always',
            value: 5
          }
        ]
      },
      {
        title: 'In the last 30 days… I found social settings upsetting',
        api_name: 'social',
        answers: [
          {
            type: 'long',
            title: 'Never',
            value: 1
          },
          {
            type: 'long',
            title: 'Rarely',
            value: 2
          },
          {
            type: 'long',
            title: 'Sometimes',
            value: 3
          },
          {
            type: 'long',
            title: 'Often',
            value: 4
          },
          {
            type: 'long',
            title: 'Always',
            value: 5
          }
        ]
      },
      {
        title: 'In the last 30 days… I had trouble staying focused on tasks',
        api_name: 'focus',
        answers: [
          {
            type: 'long',
            title: 'Never',
            value: 1
          },
          {
            type: 'long',
            title: 'Rarely',
            value: 2
          },
          {
            type: 'long',
            title: 'Sometimes',
            value: 3
          },
          {
            type: 'long',
            title: 'Often',
            value: 4
          },
          {
            type: 'long',
            title: 'Always',
            value: 5
          }
        ]
      },
      {
        title:
          'In the last 30 days… Anxiety or fear interfered with my ability to do the things I needed to do at work or at home',
        api_name: 'anxiety',
        answers: [
          {
            type: 'long',
            title: 'Never',
            value: 1
          },
          {
            type: 'long',
            title: 'Rarely',
            value: 2
          },
          {
            type: 'long',
            title: 'Sometimes',
            value: 3
          },
          {
            type: 'long',
            title: 'Often',
            value: 4
          },
          {
            type: 'long',
            title: 'Always',
            value: 5
          }
        ]
      }
    ]
  }
]

export default data
