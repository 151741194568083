import React from 'react'
import styled from 'styled-components/macro'
import { navigate } from '@reach/router'

import { Popup, PopupText } from '../../components/PageHeader/Components'
import Button from './Button'
import arrow from './icons/arrow.svg'
import invertedArrow from './icons/inverted-arrow.svg'
import getLink from '../../components/utils/routeNavigation'
import SmallCheckbox from '../../components/SmallCheckbox/SmallCheckbox'

const Component = styled.div`
  display: ${props => props.topVisible ? 'none' : 'flex'};
  position: fixed;
  min-height: 56px;
  bottom: 0;
  width: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.30);

  @media only screen and (min-width: 768px) {
    display: flex;
    padding: 12px 48px;
    min-height: 88px;
  }

  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    padding-top: 16px;
  }
`

const Content = styled.div`
  /* max-width: 1328px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const PopupWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: -60px;
`

const CheckBoxWrapper = styled.div`
  margin-right: 8px;
  /* adding border to check */
  & > div:last-of-type > div:first-of-type {
    border: 1px solid ${(props) => props.theme.colors.primaryAccent};
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`
const ButtonWrapper = styled.div``

const Link = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: none;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primaryAccent};
  }
`

const Floating = ({
  popupShown,
  agreed,
  toggle,
  setPopupShown,
  routePath,
  topVisible
}) => (
  <Component topVisible={topVisible}>
    <Content>
      {popupShown && (
        <PopupWrapper>
          <Popup>
            <PopupText>
              Please confirm that you agree to the Terms of Use
            </PopupText>
          </Popup>
        </PopupWrapper>
      )}
      <CheckBoxWrapper>
        <SmallCheckbox accepted={agreed} onClick={toggle}>
          <span onClick={toggle}>
            By clicking this button you confirm that you have read and agree to
            the
          </span>{' '}
          <Link
            rel='noopener noreferrer'
            target='_blank'
            href={getLink(routePath, '/terms')}
          >
            Terms of Use
          </Link>
          .
        </SmallCheckbox>
      </CheckBoxWrapper>
      <ButtonWrapper>
        <Button
          onClick={
            agreed
              ? () => navigate(getLink(routePath, '/survey'))
              : () => setPopupShown(true)
          }
          icon={arrow}
          smallIcon
          invertedIcon={invertedArrow}
          title='Begin now'
          inverted
          noIconMobile
        />
      </ButtonWrapper>
    </Content>
  </Component>
)

export default Floating
