import React from 'react'
import { View, Image, Link as PdfLink } from '@react-pdf/renderer'

import arrow from './img/arrow.png'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  arrowWrapper: {
    width: 5,
    justifyContent: 'center',
    marginLeft: 3
  },
  arrow: {},
  link: {
    color: '#42567A',
    fontSize: 8,
    fontFamily: 'Proxima Nova Bold'
  },
  biggerLink: {
    color: '#42567A',
    fontSize: 10,
    fontFamily: 'Proxima Nova Bold'
  }
}

const Link = ({ bigger, url, label }) => (
  <View style={styles.wrapper}>
    <PdfLink style={bigger ? styles.biggerLink : styles.link} src={url}>
      {label}
    </PdfLink>
    <View style={styles.arrowWrapper}>
      <Image src={arrow} style={styles.arrow} />
    </View>
  </View>
)

export default Link
