import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'

import close from './icons/x.svg'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 25px;

  @media only screen and (min-width: 768px) {
    padding: 50px;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1016px;
  position: relative;
`

const Close = styled.button`
  background-color: #fff;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
    top: -40px;
    right: -40px;
  }
`

const Icon = styled.img`
  width: 12px;
  height: 12px;

  @media only screen and (min-width: 768px) {
    width: 16px;
    height: 16px;
  }
`

const getEmbed = (url) => {
  const splitter = '/watch?v='
  const modifiedUrl = url.split(splitter)
  const embedString = `${modifiedUrl[0]}/embed/${modifiedUrl[1]}`
  return embedString
}

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 10px;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Embed = ({ onClose, url }) => (
  <Wrapper onClick={onClose}>
    <InnerWrapper>
      <Close onClick={onClose}>
        <Icon src={close} alt='Close' />
      </Close>
      <Container>
        <Iframe
          src={`${getEmbed(url)}?rel=0&amp;showinfo=0;autoplay=1`}
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </Container>
    </InnerWrapper>
  </Wrapper>
)

const Modal = ({ onClose, url }) =>
  ReactDOM.createPortal(
    <Embed onClose={onClose} url={url} />,
    document.getElementById('modal')
  )

export default Modal
