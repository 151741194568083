import React, { Component } from 'react'
import styled, { css } from 'styled-components/macro'

const getSize = (third, short) => {
  if (third) {
    return '32%'
  } else if (short) {
    return '47%'
  } else {
    return '100%'
  }
}

const Wrapper = styled.div`
  width: ${(props) => (props.short ? '47%' : '100%')};
  margin-bottom: 1.25rem;
  padding: 1rem;
  font-size: 18px;
  border-radius: 4px;
  background: #fff;
  transition: all 400ms ease-in-out;

  color: #2d3142;
  border: ${(props) => `1px solid ${props.theme.colors.primaryAccent}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 3.875rem;
  user-select: none;

  ${(props) =>
    props.checked &&
    css`
      background: ${props.theme.colors.primary};
      color: #fff;
      border: 1px solid ${props.theme.colors.primary};
    `}

  @media only screen and (min-width: 768px) {
    width: ${(props) => getSize(props.third, props.short)};
  }

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const Circle = styled.div`
  flex-shrink: 0;
  border: 1px solid ${(props) => props.theme.colors.primaryAccent};
  position: relative;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #fff;
`

const Check = styled.div`
  &:after {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: 12px;
    width: 6px;
    transform-origin: left top;
    border-right: 2px solid ${(props) => props.theme.colors.primaryAccent};
    border-top: 2px solid ${(props) => props.theme.colors.primaryAccent};
    content: '';
    left: 4px;
    top: 12px;
    position: absolute;
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 6px;
      opacity: 1;
    }
    40% {
      height: 12px;
      width: 6px;
      opacity: 1;
    }
    100% {
      height: 12px;
      width: 6px;
      opacity: 1;
    }
  }
`

class CheckButton extends Component {
  state = {
    // this is an antipattern, but since we have constantly changing stuff
    // needs refactor
    checked: this.props.checked || false,
    disabled: this.props.disabled || false
  }

  handleClick = () => {
    this.setState({ disabled: true, checked: true })

    if (this.props.onQuestionAnswered) {
      this.props.onQuestionAnswered()
    }
  }

  render () {
    const { children, short, third } = this.props
    const { checked, disabled } = this.state

    return (
      <Wrapper
        checked={checked}
        short={short}
        third={third}
        role='button'
        onClick={!disabled ? this.handleClick : undefined}
      >
        {children}
        <Circle>{checked && <Check />}</Circle>
      </Wrapper>
    )
  }
}

export default CheckButton
