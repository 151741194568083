/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import styled from 'styled-components/macro'
import { saveAs } from 'file-saver'

import informationFile from './Participant Information Statement.txt'
import informationFileMMWB from './Participant Information Statement - My mental wellbeing.txt'
import informationFileTAL from './Participant Information Statement - TAL.txt'
import { Logo, Content } from '../../routes/Terms/Terms'
import Loader from '../Loader/Loader'
import { Text } from './Components'
import { MMWB, MMWBInfo } from './MMWB'
import { TAL, TALInfo } from './TAL'
import { General, GeneralInfo } from './General'

const Component = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 1;
  background: ${(props) => props.theme.colors.primaryAccent};
  padding: 24px;

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  }
`

const ButtonsWrapper = styled.div`
  @media only screen and (min-width: 576px) {
    display: flex;
    max-width: 424px;
    margin: 0 auto;
    margin-bottom: 74px;
  }
`

const Button = styled.button`
  position: relative;
  width: 100%;
  padding: 16px 48px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  background: ${(props) =>
    props.secondary ? 'none' : props.theme.colors.primary};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.primary
      : props.theme.colors.primaryAccent};
  font-family: 'Proxima Nova Semibold';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;

  &:first-of-type {
    margin-bottom: 8px;
  }

  @media only screen and (min-width: 576px) {
    max-width: 200px;
    &:first-of-type {
      margin-bottom: 0;
      margin-right: auto;
    }
  }

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const Download = styled.span`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }
`

const save = (fund) => {
  if (fund === 'mymentalwellbeing') {
    saveAs(informationFileMMWB, 'Participant Information Statement.txt')
  }
  if (fund === 'tal' || fund === 'taladviser') {
    saveAs(informationFileTAL, 'Participant Information Statement.txt')
  } else {
    saveAs(informationFile, 'Participant Information Statement.txt')
  }
}

const getFundInfo = (fund) => {
  if (fund === 'mymentalwellbeing') {
    return <MMWBInfo />
  } else if (fund === 'tal' || fund === 'taladviser') {
    return <TALInfo />
  } else {
    return <GeneralInfo />
  }
}

const getFundContent = (fund) => {
  if (fund === 'mymentalwellbeing') {
    return <MMWB />
  } else if (fund === 'tal' || fund === 'taladviser') {
    return <TAL />
  } else {
    return <General />
  }
}

const Info = ({ onAgreed, isLoading, fund }) => (
  <Component>
    <Logo>Headlight</Logo>
    <Content>
      {getFundInfo(fund)}
      <Text>
        Download your copy of this information{' '}
        <Download onClick={() => save(fund)}>here</Download>.
      </Text>
      {getFundContent(fund)}
      <ButtonsWrapper>
        <Button secondary onClick={() => onAgreed(false)}>
          I disagree
        </Button>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => onAgreed(true)}
        >
          {isLoading ? <Loader /> : 'I agree'}
        </Button>
      </ButtonsWrapper>
    </Content>
  </Component>
)

export default Info
