import React from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'

import { Info } from './Components'
import linkArrow from '../../components/Card/arrow.svg'

const Component = styled.div`
  @media only screen and (min-width: 992px) {
    max-width: 880px;
    margin: 0 auto;
  }
`

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 8px;

  @media only screen and (min-width: 992px) {
    font-size: 56px;
    margin-bottom: 2rem;
    line-height: 64px;
    letter-spacing: -1px;
  }
`

const Link = styled(ReactGA.OutboundLink)`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova Bold';
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: none;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
  margin: 8px 0 40px;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    font-size: 20px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
`

const ArrowIcon = styled.img`
  width: 12x;
  margin-left: 14px;
`

const EAP = ({ data }) => {
  const { title, text, link } = data
  return (
    <Component>
      <Title>{title}</Title>
      <Info>{text}</Info>
      <Link
        rel='noopener noreferrer'
        target='_blank'
        href={link.url}
        eventLabel={link.url}
        to={link.url}
      >
        {link.label}
        <ArrowIcon src={linkArrow} alt='arrow' />
      </Link>
    </Component>
  )
}

export default EAP
