import React from 'react'
import styled from 'styled-components/macro'

import HugeTitle from './HugeTitle'
import { Title, Info } from './Components'
import AccordionHuge from '../../components/Accordion/Huge'
import scribble from './lifestyle.svg'
import download from './download.svg'
import Pdf from './Pdf/Pdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Arrows from '../../components/PageHeader/Arrows'

const Component = styled.div`
  margin-bottom: 48px;

  @media only screen and (min-width: 992px) {
    margin-top: 96px;
    margin-bottom: 96ox;
  }
`

const InnerWrapper = styled.div`
  @media only screen and (min-width: 992px) {
    max-width: 880px;
    margin: 0 auto;
  }
`

const getAccordions = (recommendations) => {
  const elements = []
  // it's an object, not an array
  Object.keys(recommendations).forEach((key) => {
    // overall item/section like cognitive, alcohol
    // can consists of multiple cards
    const item = recommendations[key]
    const element = (
      <AccordionHuge
        title={item.title}
        key={item.title + item.content[0].text}
        data={item.content}
        space
      />
    )

    elements.push(element)
  })

  return elements
}

const LifeStyleScribble = styled.img`
  display: none;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: block;
    margin: 96px 0;

    &:last-of-type {
      margin-bottom: 158px;
    }
  }
`

const DownloadSection = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 40px 0 96px;
    align-items: center;
  }
`

const AccordionsWrapper = styled.div``

const Button = styled(PDFDownloadLink)`
  padding: 16px 50px;
  max-width: 338px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primaryAccent};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: 'Proxima Nova Bold';
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  color: #fff;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
`

const Icon = styled.img`
  margin-left: 12px;
`

const SmallInfo = styled.p`
  max-width: 657px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
`

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const LifeStyleFactors = ({ data, id, pdfData }) => {
  const { hugeTitle, modifiable } = data

  if (!data || !data.modifiable) {
    return (
      <Component id={id}>
        <LifeStyleScribble src={scribble} alt='Scribble' />
        <InnerWrapper>
          <HugeTitle title='Lifestyle Factors' />
          <Title>Risk factors you can do something about</Title>
          <Info>
            Headlight has not detected any life style factors that present a
            risk to you.
          </Info>
        </InnerWrapper>
        <ArrowsWrapper>
          <Arrows dark />
        </ArrowsWrapper>
        <LifeStyleScribble src={scribble} alt='Scribble' />
      </Component>
    )
  } else {
    return (
      <Component id={id}>
        <LifeStyleScribble src={scribble} alt='Scribble' />
        <InnerWrapper>
          <HugeTitle title={hugeTitle} />
          <Title>{modifiable.title}</Title>
          <Info>{modifiable.text}</Info>
        </InnerWrapper>
        <AccordionsWrapper>
          {getAccordions(modifiable.recommendations)}
        </AccordionsWrapper>
        <DownloadSection>
          <Button document={<Pdf data={pdfData} />} fileName='report.pdf'>
            {({ blob, url, loading, error }) =>
              loading ? (
                'Loading...'
              ) : (
                <>
                  <span>Download your report</span>
                  <Icon src={download} alt='download' />
                </>
              )}
          </Button>
          <SmallInfo>
            Your mental health is constantly changing. It’s important you check
            your score and suggested resources from time to time using
            Headlight, especially if you are feeling unusually down or anxious .
            To protect your privacy, none of your current results are saved so
            make sure you download a report.
          </SmallInfo>
        </DownloadSection>
        <ArrowsWrapper>
          <Arrows dark />
        </ArrowsWrapper>
        <LifeStyleScribble src={scribble} alt='Scribble' />
      </Component>
    )
  }
}

export default LifeStyleFactors
