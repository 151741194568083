/* eslint-disable camelcase */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import uuid from 'uuid/v4'

import CheckButton from '../CheckButton/CheckButton'
import Slider from '../Slider/Slider'
import MultipleSlider from '../Slider/MultipleSlider'
import Input from '../Input/Input'
import AlternativeButton from '../Button/AccentButton'
import Levels from './Levels'
import { Title, Content, ButtonWrapper } from './Components'

const Wrapper = styled.div`
  padding: 1.25rem 1.5625rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  /* position: relative; */

  @media only screen and (min-width: 768px) {
    max-width: 706px;
  }

  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    display: block;
    transform: translate(-50%, -50%);
  }
`

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.addition ? 'flex-start' : 'center')};
  justify-content: space-between;
  margin-bottom: 18px;
  border: 1px solid ${(props) => props.theme.colors.primaryAccent};
  padding: 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }

  @media only screen and (min-width: 768px) {
    width: 49%;
    margin-bottom: 16px;
  }
`

const Checkbox = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.primaryAccent};
  background: ${(props) =>
    props.checked ? props.theme.colors.primary : '#fff'};
`

const Check = styled.div`
  &:after {
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: 10px;
    width: 6px;
    transform-origin: left top;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    content: '';
    left: 5px;
    top: 11px;
    position: absolute;
  }
`

const LabelContainer = styled.div``

const Label = styled.p`
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.primaryAccent};
  line-height: 17px;
  white-space: nowrap;
`

const Addition = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyDark};
  margin-top: 10px;
`

const MultipleSliders = styled.div`
  width: 100%;
`

const Info = styled.p`
  font-size: 20px;
  line-height: 32px;
  margin-top: 8px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;
`

class Questions extends Component {
  state = {
    buttonAnswer: '',
    disabled: false,
    multipleAnswers: [],
    multipleSliders: {},
    topAnswer: '',
    topDisabled: false,
    bottomAnswer: '',
    bottomDisabled: false
  }

  handleSingleQuestionAnswer = (answer) => (number) => {
    const { data } = this.props

    const type = data.answers[0].type

    const apiName = data.api_name
    let value = null

    if (type === 'short' || type === 'long') {
      value = data.answers.find((item) => item.title === answer).value

      this.setState({ buttonAnswer: answer, disabled: true })
    } else if (type === 'range' || type === 'input') {
      value = number
    }

    if (number) {
      this.setState({ buttonAnswer: '', disabled: false })
      this.props.onQuestionAnswered(data.title, answer, apiName, value)
    } else {
      // animation purposes
      setTimeout(() => {
        this.setState({ buttonAnswer: '', disabled: false })
        this.props.onQuestionAnswered(data.title, answer, apiName, value)
      }, 400)
    }
  }

  handleSelect = (value) => () => {
    const { multipleAnswers } = this.state

    // if there's that option - remove it
    const filteredAnswer = multipleAnswers.filter((item) => item !== value)

    const cleanAnswers = filteredAnswer.filter((item) => {
      if (value === 'None of the above') {
        // clicked none - remove all other options
        // returning empty array since we will add an option below
        return item === 'None of the above'
      } else {
        // clicked other - remove none
        return item !== 'None of the above'
      }
    })

    if (filteredAnswer.length < multipleAnswers.length) {
      // we removed that option in filteredAnswer
      this.setState({ multipleAnswers: cleanAnswers })
    } else {
      this.setState({ multipleAnswers: [...cleanAnswers, value] })
    }
  }

  handleMultipleSliders = (apiName, value) => {
    const newValue = { ...this.state.multipleSliders }
    newValue[apiName] = value
    this.setState({ multipleSliders: newValue })
  }

  handleMultipleSlidersNext = () => {
    const { data } = this.props

    const apiName = data.api_name
    const answer = 'Multiple sliders'
    const value = this.state.multipleSliders

    this.props.onQuestionAnswered(data.title, answer, apiName, value)
    this.setState({
      multipleSliders: {}
    })
  }

  handleNext = () => {
    const { data } = this.props

    const apiName = data.api_name
    const value = this.state.multipleAnswers
    const answer = 'Multiple'

    this.props.onQuestionAnswered(data.title, answer, apiName, value)
    this.setState({ multipleAnswers: [] })
  }

  getChecked = (value) => {
    const { multipleAnswers } = this.state
    const filteredAnswer = multipleAnswers.filter((item) => item === value)[0]

    return !!filteredAnswer
  }

  getQuestions = () => {
    const { data } = this.props
    if (data.question_type && data.question_type === 'multiple') {
      // render multiple options
      return (
        <>
          {data.answers.map(({ title, value, addition }) => (
            <Option
              addition={addition}
              key={title}
              onClick={this.handleSelect(value)}
            >
              <LabelContainer>
                <Label>{title}</Label>
                {addition && <Addition>{addition}</Addition>}
              </LabelContainer>
              <Checkbox checked={this.getChecked(value)}>
                {this.getChecked(value) && <Check />}
              </Checkbox>
            </Option>
          ))}
          <ButtonWrapper>
            <AlternativeButton
              onClick={this.handleNext}
              disabled={this.state.multipleAnswers.length === 0}
            >
              Next
            </AlternativeButton>
          </ButtonWrapper>
        </>
      )
    }
    if (data.question_type && data.question_type === 'multipleSliders') {
      // render multiple sliders
      return (
        <>
          <MultipleSliders>
            {data.answers.map(({ title, api_name, max, left, right }) => (
              <MultipleSlider
                title={title}
                key={api_name}
                apiName={api_name}
                max={max}
                left={left}
                right={right}
                value={this.state.multipleSliders[api_name]}
                onQuestionAnswered={this.handleMultipleSliders}
              />
            ))}
          </MultipleSliders>
          <ButtonWrapper>
            <AlternativeButton onClick={this.handleMultipleSlidersNext}>
              Next
            </AlternativeButton>
          </ButtonWrapper>
        </>
      )
    } else {
      return data.answers.map((answer) =>
        this.getElement(answer, data.api_name)
      )
    }
  }

  getElement = (answer, api_name) => {
    const { buttonAnswer, disabled } = this.state
    // should we remove UUID?

    if (answer.type === 'short') {
      return (
        <CheckButton
          short
          key={answer.title + api_name}
          onQuestionAnswered={
            !disabled && this.handleSingleQuestionAnswer(answer.title)
          }
          checked={buttonAnswer === answer.title}
          disabled={buttonAnswer !== ''}
        >
          {answer.title}
        </CheckButton>
      )
    } else if (answer.type === 'long') {
      return (
        <CheckButton
          key={answer.title + api_name}
          onQuestionAnswered={
            !disabled && this.handleSingleQuestionAnswer(answer.title)
          }
          checked={buttonAnswer === answer.title}
          disabled={buttonAnswer !== ''}
        >
          {answer.title}
        </CheckButton>
      )
    } else if (answer.type === 'range') {
      return (
        <Slider
          key={uuid()}
          max={answer.max}
          left={answer.left}
          right={answer.right}
          onQuestionAnswered={this.handleSingleQuestionAnswer(answer.title)}
        />
      )
    } else if (answer.type === 'input') {
      return (
        <Input
          key={uuid()}
          min={answer.min}
          max={answer.max}
          onQuestionAnswered={this.handleSingleQuestionAnswer(answer.title)}
        />
      )
    }
  }

  render () {
    const { data, onQuestionAnswered } = this.props
    const isLevels = data.question_type === 'levels'

    return (
      <Wrapper>
        {isLevels ? (
          <Levels data={data} onQuestionAnswered={onQuestionAnswered} />
        ) : (
          <>
            <Title noMargin={data.info}>{data.title}</Title>
            {data.info && <Info>{data.info}</Info>}
            <Content>{this.getQuestions()}</Content>
          </>
        )}
      </Wrapper>
    )
  }
}

export default Questions
