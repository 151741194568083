import React from 'react'
import { Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer'

import alcohol from './img/cards/alcohol.png'
import carer from './img/cards/carer.png'
import cognitive from './img/cards/cognitive.png'
import exercise from './img/cards/exercise.png'
import mindfulness from './img/cards/mindfulness.png'
import professional from './img/cards/professional.png'
import scribbleImage from './img/scribble.png'
import sharedStyles from './sharedStyles'

import Link from './Link'
import Header from './Header'
import Footer from './Footer'

const pageProps = {
  size: 'A4',
  wrap: false
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F9F7F7',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Regular'
  },
  content: {
    padding: '0 84',
    flex: 1,
    justifyContent: 'center'
  },
  innerContent: {},
  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 36,
    marginTop: 16
  },
  card: {
    border: '1px solid #f49188',
    borderRadius: '4px',
    padding: 13,
    width: '32%',
    backgroundColor: '#fff'
  },
  cardImageWrapper: {
    minHeight: 80,
    justifyContent: 'center',
    marginTop: 13,
    marginBottom: 10
  },
  cardImageHolder: {
    width: 65,
    display: 'flex',
    alignSelf: 'center'
  },
  cardImage: {},
  scribbleWrapper: {
    marginBottom: 39
  },
  scribbleImage: {}
})

export const getImage = (image, title) => {
  switch (image) {
    case 'alcohol':
      return <Image style={styles.cardImage} src={alcohol} alt={title} />
    case 'carer':
      return <Image style={styles.cardImage} src={carer} alt={title} />
    case 'cognitive':
      return <Image style={styles.cardImage} src={cognitive} alt={title} />
    case 'mindfulness':
      return <Image style={styles.cardImage} src={mindfulness} alt={title} />
    case 'professional':
      return <Image style={styles.cardImage} src={professional} alt={title} />
    case 'exercise':
      return <Image style={styles.cardImage} src={exercise} alt={title} />
    default:
      return <Image style={styles.cardImage} src={alcohol} alt={title} />
  }
}

const getCards = (recommendations) => {
  const elements = []
  // it's an object, not an array
  Object.keys(recommendations).forEach((key) => {
    // overall item/section like cognitive, alcohol
    // can consists of multiple cards
    const item = recommendations[key]
    item.content.forEach((card) => {
      const { text, link } = card
      const { title, image } = item
      // one particular card element
      const element = (
        <View style={styles.card} key={title + text}>
          <Text style={sharedStyles.smallTitle}>{title}</Text>
          <View style={styles.cardImageWrapper}>
            <View style={styles.cardImageHolder}>{getImage(image, title)}</View>
          </View>
          <Text style={sharedStyles.textDensed}>{text}</Text>
          <Link url={link.url} label={link.label} />
        </View>
      )
      elements.push(element)
    })
  })

  return elements
}

const Mental = ({ dq5, lifestyleFactors }) => {
  return (
    <Page {...pageProps} style={styles.page}>
      <Header />
      <View style={styles.content}>
        <View style={styles.innerContent}>
          <Text style={sharedStyles.title}>{dq5.hugeTitle}</Text>
          <Text style={sharedStyles.subtitle}>{dq5.title}</Text>
          <Text style={sharedStyles.text}>{dq5.text}</Text>
          <View style={styles.cards}>{getCards(dq5.recommendations)}</View>
          <View style={styles.scribbleWrapper}>
            <Image src={scribbleImage} style={styles.scribble} />
          </View>
          <Text style={sharedStyles.title}>{lifestyleFactors.hugeTitle}</Text>
          <Text style={sharedStyles.subtitle}>
            {lifestyleFactors.modifiable.title}
          </Text>
          <Text style={sharedStyles.text}>
            {lifestyleFactors.modifiable.text}
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  )
}

export default Mental
