import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Link } from '@reach/router'
import ReactGA from 'react-ga'

import getLink from '../../components/utils/routeNavigation'

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)``

const NotFound = ({ routePath }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <Wrapper>
      <h1>
        Page not found{' '}
        <span role='img' aria-label='sorry'>
          😢
        </span>
      </h1>
      <StyledLink to={getLink(routePath, '/')}>
        <h4>Go back home</h4>
      </StyledLink>
    </Wrapper>
  )
}

export default NotFound
