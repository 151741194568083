import React from 'react'

import { Header } from '../../routes/Terms/Terms'
import {
  Title,
  Text,
  List,
  Item,
  About,
  Subcontainer,
  Subheader,
  Subtext
} from './Components'

const MMWB = () => (
  <>
    <Title>PARTICIPANT CONSENT FORM</Title>
    <Text mb>I agree to take part in this research study.</Text>
    <Text>In giving my consent I state that:</Text>
    <List>
      <Item>
        I understand the purpose of the study, what I will be asked to do, and any risks/benefits involved.
      </Item>
      <Item>
        I have read the Participant Information Statement and have been able to discuss my involvement in the study with the researchers if I wished to do so.
      </Item>
      <Item>
        The above information  has answered any questions that I had about the study and I am happy with the answers.
      </Item>
      <Item>
        I understand that being in this study is completely voluntary and I do not have to take part. My decision whether to be in the study will not affect my relationship with the researchers or anyone else at the University of Sydney or my Superannuation Fund or TAL or you my use of the HEADLIGHT tool now or in the future.
      </Item>
      <Item>
        I understand that my questionnaire responses cannot be withdrawn once they are submitted, as they are anonymous and therefore the researchers will not be able to tell which one is mine.
      </Item>
      <Item>
        I understand that the results of this study may be published, and that publications will not contain my name or any identifiable information about me. I understand that the researchers will provide an aggregated report of the research findings to my Superannuation Fund and TAL.
      </Item>
    </List>
  </>
)

const MMWBInfo = () => (
  <>
    <Header>PARTICIPANT INFORMATION STATEMENT</Header>
    <About>
      <Subcontainer>
        <Subheader>(1) What is this study about?</Subheader>
        <Subtext>
          You are invited to take part in a research study to explore characteristics that are associated with the uptake of extra resources after receiving personal mental health feedback online. If you agree, your HEADLIGHT Non-Personally Identifiable Information (i.e. the research team will not know it is you) will be combined with all the responses from (a) other members from your Superannuation Fund who agree to participate in the research study and (b) other participants in the research study generally, and provided to Prof Glozier at the University of Sydney.
        </Subtext>
        <Subtext>
          This Participant Information Statement tells you about the research study. Knowing what is involved will help you decide if you want to take part in the research. Please read this sheet carefully. 
        </Subtext>
        <Subtext>Participation in this research study is voluntary.</Subtext>
        <Subtext>
          By giving your consent to take part in this study you are telling us
          that you:
          <br />
          ✓ Understand what you have read.
          <br />
          ✓ Agree to take part in the research study as outlined below.
          <br />
          ✓ Agree to the use of your non-personally identifiable information as described.
          <br />
        </Subtext>
        <Subheader>(2) Who is running the study?</Subheader>
        <Subtext>
          The study is being carried out by the following researchers:
          <br />
          • Professor Nick Glozier, Brain and Mind Centre, University of Sydney
          <br />
          • Dr Isabella Choi, Brain and Mind Centre, University of Sydney
          <br />
        </Subtext>
        <Subtext>This study is being funded by TAL.</Subtext>
        <Subheader>(3) What will the study involve for me?</Subheader>
        <Subtext>
          If you agree to participate in this study by clicking on the “I agree” button at the end of the HEADLIGHT tool, information about your wellbeing in the HEADLIGHT tool will be deidentified and combined with other users’ data. This will be securely sent to the University of Sydney’s servers where the researchers will analyse the data. You need to do nothing else except use the tool as normal.
        </Subtext>
        <Subheader>(4) How much of my time will the study take?</Subheader>
        <Subtext>
          The study will take no more time than you would normally spend using
          the HEADLIGHT tool.
        </Subtext>
        <Subheader>(5) Who can take part in the study?</Subheader>
        <Subtext>
          You can participate in this study if you have completed the questionnaire and agree to submit your responses.
        </Subtext>
        <Subheader>
          (6) Do I have to be in the study? Can I withdraw from the study once
          I've started?
        </Subheader>
        <Subtext>
          Being in this study is completely voluntary and you do not have to take part. Your decision whether to participate will not affect your current or future relationship with your Superannuation Fund, TAL, the researchers or anyone else at the University of Sydney, or your use of the HEADLIGHT tool.
        </Subtext>
        <Subtext>
          Clicking on the “I agree” button at the bottom of this page is an indication of your consent to participate in the study. Once you have clicked “I agree”, your responses cannot be withdrawn because they are anonymous and therefore we will not be able to tell which one is yours.
        </Subtext>
        <Subheader>
          (7) Are there any risks or costs associated with being in the study?
        </Subheader>
        <Subtext>
          Aside from giving up your time, we do not expect that there will be any risks or costs associated with taking part in this study.
        </Subtext>
        <Subheader>
          (8) Are there any benefits associated with being in the study?
        </Subheader>
        <Subtext>
          We (the researchers) cannot guarantee that you will receive any direct benefits from being in the study but this information will be used to help plan future wellbeing programs.
        </Subtext>
        <Subheader>
          (9) What will happen to information about me that is collected during
          the study?
        </Subheader>
        <Subtext>
          By providing your consent, you are agreeing to us collecting personal information about you for the purposes of this research study. Your information will only be used for the purposes outlined in this Participant Information Statement.
        </Subtext>
        <Subtext>
          All the data is stored on secure servers only accessible from the University of Sydney by the researchers. The data collected from this study will be stored securely for 5 years and will then be permanently deleted.
        </Subtext>
        <Subtext>
          Study findings may be published, but you will not be individually identifiable in these publications.
        </Subtext>
        <Subheader>(10) Can I tell other people about the study?</Subheader>
        <Subtext>
          Yes, you are welcome to tell other people about the study.
        </Subtext>
        <Subheader>
          (11) What if I would like further information about the study?
        </Subheader>
        <Subtext>
          When you have read this information, Prof Glozier and the research team will be available to discuss it with you further and answer any questions you may have. If you would like to know more at any stage during the study, please feel free to contact Prof Glozier (02) 9515 1593.
        </Subtext>
        <Subheader>
          (12) What if I have a complaint or any concerns about the study?
        </Subheader>
        <Subtext>
          Research involving humans in Australia is reviewed by an independent group of people called a Human Research Ethics Committee (HREC). The ethical aspects of this study have been approved by the HREC of the University of Sydney [project no.:2019/803]. As part of this process, we have agreed to carry out the study according to the National Statement on Ethical Conduct in Human Research (2007). This statement has been developed to protect people who agree to take part in research studies.
        </Subtext>
        <Subtext>
          If you are concerned about the way this study is being conducted or you wish to make a complaint to someone independent from the study, please contact the university using the details outlined below. Please quote the study title and protocol number.
        </Subtext>
        <Subtext>
          The Manager, Ethics Administration, The University of Sydney:
          <br />• Telephone: <a href='tel:+61286278176'>+61 2 8627 8176</a>
          <br />• Email:{' '}
          <a href='mailto:ro.humanethics@sydney.edu.au'>
            ro.humanethics@sydney.edu.au
          </a>
          <br />• Fax: <a href='fax:+61286278176'>+61 2 8627 8177</a>{' '}
          (Facsimile)
        </Subtext>
        <Subtext>This information sheet is for you to keep</Subtext>
      </Subcontainer>
    </About>
  </>
)

export { MMWB, MMWBInfo }
