import React from 'react'
import styled from 'styled-components/macro'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  /* basic margin when stacked at mobile */
  & > div {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 56px;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`

const CardSection = ({ children }) => <Component>{children}</Component>

export default CardSection
