import styled from 'styled-components/macro'

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyLight};
  margin-bottom: ${(props) => (props.mb ? '15px' : 0)};
`

const Title = styled.h3`
  color: #fff;
  font-family: 'Proxima Nova Bold';
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.5px;
  margin-top: 32px;
  margin-bottom: 8px;
`

const List = styled.ul`
  padding-left: 15px;
  margin-bottom: 48px;
`

const Item = styled.li`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyLight};

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`

const About = styled.div`
  background: ${(props) => props.theme.colors.greyLight};
  padding: 8px 8px 8px 32px;
  margin-bottom: 16px;
`

const Subcontainer = styled.div`
  padding-right: 45px;
  max-height: 286px;
  overflow-y: scroll;

  /* ie11 */
  scrollbar-base-color: ${(props) => props.theme.colors.primary};
  scrollbar-face-color: ${(props) => props.theme.colors.primary};
  scrollbar-highlight-color: #e9e8e8;
  scrollbar-track-color: #e9e8e8;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e9e8e8;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    filter: brightness(110%);
  }
`

const Subheader = styled.h4`
  font-family: 'Proxima Nova Semibold';
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.25px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 20px;
`

const Subtext = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.primaryAccent};
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

export { Title, Text, List, Item, About, Subcontainer, Subheader, Subtext }
