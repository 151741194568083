import React from 'react'
import styled, { css } from 'styled-components/macro'
import ReactGA from 'react-ga'

import alcohol from './img/alcohol.svg'
import carer from './img/carer.svg'
import cognitive from './img/cognitive.svg'
import exercise from './img/exercise.svg'
import mindfulness from './img/mindfulness.svg'
import professional from './img/professional.svg'
import arrow from './arrow.svg'

const Component = styled.div`
  max-width: 327px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid ${(props) => props.theme.colors.primary};
  padding: 24px;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.link &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 25px -6px ${(props) => props.theme.colors.primary};
      }
    `}

  @media only screen and (min-width: 992px) {
    padding: 24px;
    max-width: 352;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    display: block;
  }

  /* ie11 */
  @media screen and (min-width: 992px) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: flex;
  }
`

const Title = styled.p`
  font-family: 'Proxima Nova Bold';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
  color: ${(props) => props.theme.colors.primaryAccent};
`

const ImageBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 0 46px 0;

  @media only screen and (min-width: 992px) {
    height: 200px;
    margin: 35px 0;
    min-height: 200px;
  }
`

const Image = styled.img`
  max-width: ${(props) => props.maxWidth}px;
`

const Text = styled.p`
  font-family: 'Proxima Nova Regular';
  color: ${(props) => props.theme.colors.greyDark};
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
`
const Link = styled(ReactGA.OutboundLink)`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova Bold';
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: none;
  margin-top: 8px;
  font-size: 20px;
  line-height: 32px;

  @media only screen and (min-width: 992px) {
    margin-top: 2rem;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }
`

const Arrow = styled.img`
  width: 12px;
  margin-left: 14px;
`

export const getImage = (image, title) => {
  switch (image) {
    case 'alcohol':
      return <Image src={alcohol} alt={title} maxWidth={142} />
    case 'carer':
      return <Image src={carer} alt={title} maxWidth={142} />
    case 'cognitive':
      return <Image src={cognitive} alt={title} maxWidth={142} />
    case 'mindfulness':
      return <Image src={mindfulness} alt={title} maxWidth={142} />
    case 'professional':
      return <Image src={professional} alt={title} maxWidth={142} />
    case 'exercise':
      return <Image src={exercise} alt={title} maxWidth={142} />
    default:
      return <Image src={alcohol} alt={title} maxWidth={142} />
  }
}

const handleCardClick = (link) => () => {
  if (link) {
    window.open(link.url, '_blank')
    ReactGA.outboundLink(
      {
        label: link.url
      },
      // callback is required
      function () {}
    )
  }
}

const splitText = (text) => {
  return text.split('\n').map((item) => {
    return <Text key={item}>{item}</Text>
  })
}

const Card = ({ text, link, title, image }) => (
  <Component onClick={handleCardClick(link)} link={link}>
    <Title>{title}</Title>
    <ImageBox>{getImage(image, title)}</ImageBox>
    {splitText(text)}
    {link && (
      <Link
        rel='noopener noreferrer'
        target='_blank'
        href={link.url}
        eventLabel={link.url}
        to={link.url}
      >
        {link.label}
        <Arrow src={arrow} alt='arrow' />
      </Link>
    )}
  </Component>
)

export default Card
