/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'
import Observer from '@researchgate/react-intersection-observer'

import PageHeader from '../../components/PageHeader/PageHeader'
import Risks from './Risks'
import Research from './Research'
import Behind from './Behind'
import Privacy from './Privacy'
import PageFooter from '../../components/PageFooter/PageFooter'
import Floating from './Floating'
import Video from './Video'
import HowTo from './HowTo'

const Wrapper = styled.div`
  position: relative;
  /* floating */
  padding-bottom: 68px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 88px;
  }
`

const Home = ({ routePath, activateVideo, fund }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const [agreed, setAgree] = useState(false)
  const [popupShown, setPopupShown] = useState(false)
  const [headerVisible, setHeaderVisible] = useState(false)
  const [videoVisible, setVideoVisible] = useState(false)

  const toggle = () => {
    setAgree(!agreed)
    setPopupShown(false)
  }

  const handleIntersection = (e) => {
    const { isIntersecting } = e

    if (headerVisible !== isIntersecting) {
      setHeaderVisible(isIntersecting)
    }
  }

  const handleVideoVisible = (e) => {
    const { isIntersecting } = e

    if (videoVisible !== isIntersecting) {
      setVideoVisible(isIntersecting)
    }
  }

  return (
    <Wrapper>
      <Observer onChange={handleIntersection}>
        <div>
          <PageHeader
            variation='home'
            popupShown={popupShown}
            setPopupShown={setPopupShown}
            agreed={agreed}
            toggle={toggle}
            routePath={routePath}
          />
        </div>
      </Observer>
      <Video onVideoVisible={handleVideoVisible} activateVideo={activateVideo} />
      <Risks />
      <HowTo />
      <Research />
      <Behind />
      <PageHeader
        variation='move'
        popupShown={popupShown}
        setPopupShown={setPopupShown}
        agreed={agreed}
        toggle={toggle}
        routePath={routePath}
      />
      <Privacy />
      <PageFooter routePath={routePath} fund={fund} />
      <Floating
        popupShown={popupShown}
        setPopupShown={setPopupShown}
        agreed={agreed}
        toggle={toggle}
        routePath={routePath}
        topVisible={headerVisible || videoVisible}
      />
    </Wrapper>
  )
}

export default Home
