const colors = {
  primary: '#f49188',
  primaryAccent: '#42567a',
  primaryAlt: '#fff1e2',
  secondary: '#aad8c8',
  secondaryAccent: '#fff',
  secondaryAlt: '#414042',
  link: 'rgba(0, 0, 0, 0.85)',
  greyDark: '#77787b',
  greyLight: '#F9F7F7',
  grey9: '#161616',
  success: '#B3D8CB'
}

const theme = {
  colors
}

export default theme
