import React from 'react'
import styled from 'styled-components/macro'

import Card from '../../../components/Card/Card'
import CardSection from '../../../components/Card/CardsSection'
import { Title, Info } from '../Components'

const Wrapper = styled.div`
  margin-bottom: 48px;
`

const InnerWrapper = styled.div`
  @media only screen and (min-width: 992px) {
    max-width: 880px;
    margin: 0 auto;
  }
`

const getCards = (recommendations) => {
  const elements = []
  // it's an object, not an array
  Object.keys(recommendations).forEach((key) => {
    // overall item/section like cognitive, alcohol
    // can consists of multiple cards
    const item = recommendations[key]
    item.content.forEach((card) => {
      // one particular card element
      const element = (
        <Card
          text={card.text}
          link={card.link}
          title={item.title}
          image={item.image}
          key={item.title + card.text}
        />
      )
      elements.push(element)
    })
  })

  return elements
}

const DQ5A = ({ data }) => (
  <Wrapper>
    <InnerWrapper>
      <Title>{data.title}</Title>
      <Info>{data.text}</Info>
    </InnerWrapper>
    <CardSection>{getCards(data.recommendations)}</CardSection>
  </Wrapper>
)

export default DQ5A
