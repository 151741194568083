import React from 'react'
import styled from 'styled-components/macro'
import { Link as RouterLink } from '@reach/router'

import headlight from './headlight-dark.svg'
import Recommendation from './Recommendation'
import Home from './Home'
import Move from './Move'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  background-color: ${(props) => props.theme.colors.primaryAccent};
`

const MenuBar = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  margin: 0 auto;
  min-height: 60px;
  position: relative;

  @media only screen and (min-width: 992px) {
    padding: 12px 56px;
    max-width: 1328px;
    justify-content: ${(props) =>
      props.variation === 'home' || props.variation === 'recommendation'
        ? 'space-between'
        : 'center'};
  }
`

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  display: flex;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const Links = styled.div`
  display: none;

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: none;
  text-underline-position: under;
  font-size: 20px;
  line-height: 32px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    color: ${(props) => props.theme.colors.primaryAccent};
    text-decoration: none;
  }

  &:not(:last-of-type) {
    margin-right: 2rem;
  }
`

const Logo = styled.img``

const PageHeader = ({
  variation,
  dq5Inverted,
  information,
  popupShown,
  agreed,
  toggle,
  setPopupShown,
  routePath
}) => (
  <Wrapper>
    {variation !== 'move' && (
      <MenuBar>
        <Container variation={variation} className='container'>
          <StyledLink to={routePath || '/'}>
            <Logo src={headlight} alt='Headlight' />
          </StyledLink>
          {variation === 'home' && (
            <>
              <Links>
                <Link href='#research'>The Research</Link>
                <Link href='#behind'>Who's behind Headlight?</Link>
                <Link href='#privacy'>Your Privacy</Link>
              </Links>
            </>
          )}
          {variation === 'recommendation' && (
            <>
              <Links>
                <Link href='#mental'>Mental Wellbeing</Link>
                <Link href='#lifestyle'>Lifestyle Factors</Link>
                <Link href='#head'>Head to Health</Link>
              </Links>
            </>
          )}
        </Container>
      </MenuBar>
    )}
    {variation === 'recommendation' && (
      <Recommendation dq5Inverted={dq5Inverted} information={information} />
    )}
    {variation === 'home' && (
      <Home
        popupShown={popupShown}
        agreed={agreed}
        toggle={toggle}
        setPopupShown={setPopupShown}
        routePath={routePath}
      />
    )}
    {variation === 'move' && (
      <Move
        popupShown={popupShown}
        agreed={agreed}
        toggle={toggle}
        setPopupShown={setPopupShown}
        routePath={routePath}
      />
    )}
  </Wrapper>
)

export default PageHeader
