import styled from 'styled-components/macro'

const AccentButton = styled.button`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primaryAccent};
  border: 0;
  width: 100%;
  color: #fff;
  padding: 16px 48px;
  font-weight: bold;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    width: auto;
    padding: 16px 83px;
    font-size: 20px;
    line-height: 32px;
  }
`

export default AccentButton
