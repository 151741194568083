import React from 'react'
import styled from 'styled-components/macro'
import { navigate } from '@reach/router'

import AlternativeScribble from '../Scribble/Alternative'
import { Content, Title, Popup, PopupText } from './Components'
import Button from '../Button/Button'
import getLink from '../utils/routeNavigation'
import AlternativeSmallCheckbox from '../../components/SmallCheckbox/AlternativeSmallCheckbox'
import Arrows from './Arrows'

const Info = styled.h3`
  font-family: 'Proxima Nova Regular';
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  text-align: left;
  margin: 16px 0 32px 0;

  @media only screen and (min-width: 768px) {
    margin: 16px 0 24px 0;
    text-align: center;
  }
`

const CompletionTime = styled.p`
  font-family: 'Proxima Nova Regular';
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: auto;
  }

  /* ie11 */
  @media screen and (min-width: 768px) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: flex;
    justify-content: center;
  }
`

const Link = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: none;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`

const Home = ({ popupShown, agreed, toggle, setPopupShown, routePath }) => {
  return (
    <Content>
      <Title>Shine a light on your mental wellbeing blind spots.</Title>
      <Info>
        Headlight is an online tool designed to help you to better understand
        your mental health.
      </Info>
      <CompletionTime>Completion time 2-3 minutes</CompletionTime>
      <ButtonWrapper>
        <Button
          onClick={
            agreed
              ? () => navigate(getLink(routePath, '/survey'))
              : () => setPopupShown(true)
          }
          home
        >
          Explore your mental wellbeing
        </Button>
      </ButtonWrapper>
      {popupShown && (
        <Popup>
          <PopupText>
            Please confirm that you agree to the Terms of Use
          </PopupText>
        </Popup>
      )}
      <AlternativeSmallCheckbox accepted={agreed} onClick={toggle}>
        <span onClick={toggle}>
          By clicking this button you confirm that you have read and agree to
          the
        </span>{' '}
        <Link
          rel='noopener noreferrer'
          target='_blank'
          href={getLink(routePath, '/terms')}
        >
          Terms of Use
        </Link>
        .
      </AlternativeSmallCheckbox>
      <Arrows />
      <AlternativeScribble color='white' />
    </Content>
  )
}

export default Home
