import styled from 'styled-components/macro'

const Content = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    padding: 44px;
    padding-bottom: 73px;
    max-width: 1280px;
    width: 100%;
  }

  @media only screen and (min-width: 992px) {
    padding: 88px;
    padding-bottom: 85px;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    width: 100%;
  }
`

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  text-align: left;
  line-height: 48px;
  color: ${(props) => props.theme.colors.primary};

  @media only screen and (min-width: 768px) {
    font-size: 56px;
    line-height: 64px;
    text-align: center;
  }

  /* iPhone 7 */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    font-size: 34px;
    line-height: 42px;
  }
`

const Terms = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }

  @media only screen and (min-width: 992px) {
    max-width: ${(props) => (props.noWidth ? 'none' : '450px')};
    align-items: center;
  }
`

const Agree = styled.p`
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.primaryAccent : theme.colors.primary};
  line-height: normal;
  margin-right: ${(props) => (props.marginRight ? '10px' : 0)};

  @media only screen and (min-width: 768px) {
    margin-right: ${(props) => (props.marginRight ? '15px' : 0)};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${(props) => (props.small ? '14px' : '18px')};
  }
`

const Link = styled.a`
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.primaryAccent : theme.colors.primary};
  line-height: normal;

  @media only screen and (min-width: 992px) {
    font-size: ${(props) => (props.small ? '14px' : '18px')};
  }
`

const CheckBox = styled.img`
  margin-right: 12px;
  /* to align with text */
  margin-top: ${(props) => (props.marginRight ? 0 : '2px')};

  @media only screen and (min-width: 992px) {
    margin-right: ${(props) => (props.marginRight ? 0 : '20px')};
  }
`

const Popup = styled.div`
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 6px;
  margin-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
  }

  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    display: inline-flex;
    left: 50%;
    transform: translateX(-50%);
  }
`

const PopupText = styled.p`
  font-family: 'Proxima Nova Regular';
  color: ${(props) => props.theme.colors.primaryAccent};
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`

export { Content, Title, Terms, Agree, Link, CheckBox, Popup, PopupText }
