import React from 'react'
import styled from 'styled-components/macro'

import brainHeart from './icons/brain-heart.svg'
import { Title } from './Components'

const Component = styled.div`
  position: relative;
`

const ContentWrapper = styled.div`
  padding: 50px 24px;

  @media only screen and (min-width: 768px) {
    padding: 48px;
    max-width: 1120px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 992px) {
    padding: 96px;
  }

  /* ie11 */
  @media screen and (min-width: 768px) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: block;
  }
`

const Image = styled.img`
  width: 100%;
  margin-bottom: 4rem;

  @media only screen and (min-width: 768px) {
    max-width: 880px;
    margin: 40px 0 64px;
    width: 100%;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 180px;
  }
`

const TextWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    max-width: 880px;
  }
  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`

const Text = styled.p`
  font-family: 'Proxima Nova Regular';
  font-size: 20px;
  line-height: 32px;
  line-height: 2rem;
  letter-spacing: -0.25px;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`

const Risks = () => (
  <Component>
    <ContentWrapper>
      <Title>
        There are risks to mental wellbeing much like there are risks to the
        heart.
      </Title>
      <Image src={brainHeart} alt='Brain and heart' />
      <TextWrapper>
        <Text>
          The difference is that the risk factors that contribute to the health
          of your heart are now widely known. We’ve all come to understand that
          eating an unhealthy diet or not doing enough physical activity can
          lead to a higher risk of cardiovascular disease.
        </Text>
        <Text>
          It’s the same with the brain. There are risk factors, some of which
          may be surprising, that lead to a higher risk of experiencing poor
          mental wellbeing in the future.
        </Text>
      </TextWrapper>
    </ContentWrapper>
  </Component>
)

export default Risks
