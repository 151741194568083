import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
//import ReactGA from 'react-ga'

import './fonts/proxima.css'
import './styles/index.css'
import './styles/grid.css'
import './styles/hamburger.css'

import App from './App'

//ReactGA.initialize('UA-123824849-3')
//ReactGA.pageview(window.location.pathname + window.location.search)

const rootEl = document.getElementById('root')
ReactDOM.render(<App />, rootEl)

// hot reload
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(<NextApp />, rootEl)
  })
}
