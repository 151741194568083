import React, { Component } from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'

import arrowIcon from './arrow-right.svg'
import getAccordionImage from './getAccordionImage'
import { Label, Arrow } from './Components'

const Wrapper = styled.div`
  min-height: 49px;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  padding: 24px 40px;
  margin: ${(props) => (props.space ? '16px 0' : '0')};
  background: #fff;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  @media only screen and (min-width: 768px) {
    margin: ${(props) => (props.space ? '24px 0' : '0')};
    padding: 35px 40px;
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 48px;
  }

  /* ie11 */
  @media only screen and (min-width: 768px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex: 40%;

      &:first-child {
        margin-right: 48px;
        margin-bottom: 48px;
      }
    }
  }
`

const Important = styled.div`
  margin-top: 20px;
`
const Unlikely = styled.div`
  margin-top: 20px;
`
const Action = styled.div`
  margin-top: 20px;
`

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primaryAccent};
  letter-spacing: 2px;
  margin-bottom: 10px;
`

const ActionTitle = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 20px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 10px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
`
const Text = styled.p`
  font-family: 'Proxima Nova Regular';
  font-size: 18px;
  color: ${(props) => props.theme.colors.greyDark};

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

const Link = styled(ReactGA.OutboundLink)`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova Bold';
  color: ${(props) => props.theme.colors.primaryAccent};
  text-decoration: none;
  margin-top: 1rem;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
    margin-top: 3rem;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }
`

const ArrowIcon = styled.img`
  width: 20px;
  margin-left: 5px;
  transform: rotate(-90deg);
`

class AccordionHuge extends Component {
  state = {
    opened: false
  }

  handleOpen = (e) => {
    if (e.target.tagName !== 'A') {
      this.setState((prevState) => ({ opened: !prevState.opened }))
    }
  }

  render () {
    const { opened } = this.state
    const { title, space, data } = this.props
    return (
      <Wrapper role='button' onClick={this.handleOpen} space={space}>
        <Header>
          <Label>{title}</Label>
          <Arrow src={arrowIcon} opened={opened} alt='arrow' />
        </Header>
        {opened && (
          <Content>
            <Important>
              <Title>{data[0].title}</Title>
              <Text>{data[0].text}</Text>
            </Important>
            <Unlikely>
              <Title>{data[1].title}</Title>
              <Text>{data[1].text}</Text>
            </Unlikely>
            <Action>
              <ActionTitle>{data[2].card.title}</ActionTitle>
              <Text>{data[2].card.text}</Text>
              <Link
                rel='noopener noreferrer'
                target='_blank'
                href={data[2].card.link.url}
                eventLabel={data[2].card.link.url}
                to={data[2].card.link.url}
              >
                {data[2].card.link.label}
                <ArrowIcon src={arrowIcon} alt='arrow' />
              </Link>
            </Action>
            <ImageWrapper>
              {getAccordionImage(data[2].card.image, data[2].card.text)}
            </ImageWrapper>
          </Content>
        )}
      </Wrapper>
    )
  }
}

export default AccordionHuge
