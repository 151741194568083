import React, { useState } from 'react'
import styled from 'styled-components/macro'

import AlternativeButton from '../Button/AccentButton'
import { Title, Content, ButtonWrapper } from './Components'
import CheckButtonDumb from '../CheckButton/CheckButtonDumb'
import FloatingInfo from '../FloatingInfo/FloatingInfo'

const Component = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  & > h3 {
    margin-bottom: 0;
  }
`

const Levels = ({ data, onQuestionAnswered }) => {
  const [topAnswer, setTopAnswer] = useState('')
  const [bottomAnswer, setBottomAnswer] = useState('')
  const [disabled, setDisabled] = useState(false)

  const handleLevels = (answer, position) => () => {
    if (position === 'top') {
      setTopAnswer(answer)
    } else {
      setBottomAnswer(answer)
    }
  }

  const handleNext = () => {
    setDisabled(true)

    const topValue = data.answers.find((item) => item.title === topAnswer).value
    const bottomValue = data.subAnswers.find(
      (item) => item.title === bottomAnswer
    ).value

    const apiName = data.api_name
    const answer = [topAnswer, bottomAnswer]
    const value = [topValue, bottomValue]

    // animation purposes
    setTimeout(() => {
      setDisabled(false)
      setTopAnswer('')
      setBottomAnswer('')
      onQuestionAnswered(data.title, answer, apiName, value)
    }, 400)
  }

  return (
    <Component>
      <TitleWrapper>
        <Title>{data.title}</Title>
        <FloatingInfo text={data.floatingInfo} />
      </TitleWrapper>
      <Content>
        {data.answers.map((answer) => (
          <CheckButtonDumb
            short
            key={answer.title}
            onQuestionAnswered={!disabled && handleLevels(answer.title, 'top')}
            checked={topAnswer === answer.title}
            disabled={disabled}
          >
            {answer.title}
          </CheckButtonDumb>
        ))}
      </Content>
      <Title mtop>{data.subtitle}</Title>
      <Content>
        {data.subAnswers.map((answer) => (
          <CheckButtonDumb
            third
            key={answer.title}
            onQuestionAnswered={
              !disabled && handleLevels(answer.title, 'bottom')
            }
            checked={bottomAnswer === answer.title}
            disabled={disabled}
          >
            {answer.title}
          </CheckButtonDumb>
        ))}
      </Content>
      <ButtonWrapper>
        <AlternativeButton
          onClick={handleNext}
          disabled={disabled || topAnswer === '' || bottomAnswer === ''}
        >
          Next
        </AlternativeButton>
      </ButtonWrapper>
    </Component>
  )
}

export default Levels
