import React from 'react'
import styled from 'styled-components/macro'
import { Title } from './Components'

import blank from './icons/blank.svg'
import brain from './icons/brain.svg'
import bookmark from './icons/bookmark.svg'
import checkfile from './icons/checkfile.svg'

const data = [
  {
    icon: blank,
    text: 'Complete a quick and simple questionnaire taking 2-3 minutes'
  },
  {
    icon: brain,
    text: 'Receive an easy-to-understand mental wellbeing score'
  },
  {
    icon: bookmark,
    text:
      'Read the suggestions and actions you can take to help address your risk factors'
  },
  {
    icon: checkfile,
    text:
      'Utilise evidence-based resources from leading Australian and global organisations'
  }
]

const Component = styled.div`
  padding: 32px 24px;
  background-color: ${(props) => props.theme.colors.greyLight};

  @media only screen and (min-width: 768px) {
    padding: 48px;
  }

  @media only screen and (min-width: 992px) {
    padding: 96px 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Container = styled.div`
  max-width: 1328px;
  width: 100%;
`

const Steps = styled.div`
  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
  }
`

const Step = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
`

const IconWrapper = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${(props) => props.theme.colors.primaryAlt};
  background-color: #fff;
  border-radius: 50%;
  margin-right: 1rem;
  flex-shrink: 0;

  @media only screen and (min-width: 992px) {
    width: 164px;
    height: 164px;
    margin-right: 0;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 1200px) {
    width: 248px;
    height: 248px;
  }
`

const Icon = styled.img`
  @media only screen and (min-width: 992px) {
    height: 53px;
  }
`

const Content = styled.div`
  width: 100%;
`

const Header = styled.p`
  font-family: 'Proxima Nova Bold';
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 8px;

  @media only screen and (min-width: 992px) {
    text-align: center;
  }
`

const Text = styled.p`
  letter-spacing: -0.25px;

  @media only screen and (min-width: 992px) {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
  }
`

const HowTo = () => (
  <Component>
    <Container>
      <Title>How to use Headlight.</Title>
      <Steps>
        {data.map(({ icon, text }, index) => (
          <Step key={text}>
            <IconWrapper>
              <Icon src={icon} alt={text} />
            </IconWrapper>
            <Content>
              <Header>Step {index + 1}</Header>
              <Text>{text}</Text>
            </Content>
          </Step>
        ))}
      </Steps>
    </Container>
  </Component>
)

export default HowTo
