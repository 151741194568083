import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import play from './icons/play.svg'
// swap with the youtube video file in the future?
import fill from './img/fill.svg'
import Observer from '@researchgate/react-intersection-observer'

import { Title, Text } from './Components'
import Modal from './Modal'

const Component = styled.div`
  padding: 32px 24px;
  background-color: ${(props) => props.theme.colors.greyLight};

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
  }

  @media only screen and (min-width: 992px) {
    padding: 96px;
  }
`

const Preview = styled.div`
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${fill});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &:hover {
    cursor: pointer;
    filter: brightness(115%);
  }

  @media only screen and (min-width: 768px) {
    height: 474px;
    max-width: 880px;
    width: 100%;
  }
`

const Play = styled.img`
  user-select: none;
`

const Wrapper = styled.div`
  & > p {
    margin-bottom: 1.5rem;
  }

  @media only screen and (min-width: 768px) {
    max-width: 880px;
    width: 100%;
  }
`

const Video = ({ onVideoVisible, activateVideo }) => {
  useEffect(() => {
    if (activateVideo) {
      window.scrollTo(0, myRef.current.offsetTop)
      handleOpenModal()
    }
  }, [activateVideo])

  const [modalShown, setModalShown] = useState(false)

  const handleOpenModal = () => {
    setModalShown(true)
  }

  const handleCloseModal = () => {
    setModalShown(false)
  }

  const myRef = useRef(null)

  return (
    <Component ref={myRef}>
      <Wrapper>
        <Title>How it works.</Title>
        <Text>
          Headlight asks simple questions to give you a wellbeing score, and
          arms you with evidence-based resources that help you take control.
        </Text>
      </Wrapper>
      <Observer onChange={onVideoVisible}>
        <Preview onClick={handleOpenModal}>
          <Play src={play} alt='Play' />
        </Preview>
      </Observer>
      {modalShown && (
        <Modal
          onClose={handleCloseModal}
          url='https://www.youtube.com/watch?v=A-M3FqZLjKM'
        />
      )}
    </Component>
  )
}

export default Video
