import React, { useState } from 'react'
import styled from 'styled-components/macro'

const Component = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const NumberInput = styled.input`
  width: 100%;
  appearance: none;

  border-right: 0;
  border-top: 1px solid ${(props) => props.theme.colors.primaryAccent};
  border-left: 1px solid ${(props) => props.theme.colors.primaryAccent};
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryAccent};
  border-radius: 4px 0 0 4px;

  padding: 18px 25px;
  font-family: 'Proxima Nova Regular';
  font-size: 18px;
  color: ${(props) => props.theme.colors.primaryAccent};
  outline: none;

  /* rm + - buttons */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.primaryAccent};
  }
`

const Button = styled.button`
  border-right: 0;
  border: 1px solid ${(props) => props.theme.colors.primaryAccent};
  border-radius: 0 4px 4px 0;

  padding: 18px 25px;
  font-family: 'Proxima Nova Regular';
  font-size: 18px;
  outline: none;

  color: ${(props) =>
    props.touched ? '#fff' : props.theme.colors.primaryAccent};
  background: ${(props) =>
    props.touched ? props.theme.colors.primary : '#fff'};

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const Popup = styled.div`
  position: absolute;
  top: calc(-100% + 12px);
  width: 100%;
  max-width: 330px;
  left: 50%;
  transform: translateX(-50%);
  background: ${(props) => props.theme.colors.primaryAccent};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 7px);
    border-radius: 2px;
    left: calc(50% - 5px);
    transform: translate(-50%, 0%);
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.colors.primaryAccent};
    transform: rotate(45deg);
  }

  @media only screen and (min-width: 768px) {
    top: calc(-100% + 12px);
  }
`

const PopupText = styled.p`
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
`

const Input = ({ min, max, onQuestionAnswered }) => {
  const [touched, setTouched] = useState(false)
  const [number, setNumber] = useState('')
  const [error, setError] = useState(false)

  const handleChange = (e) => {
    const { value } = e.target

    setError(false)
    setNumber(value)
    setTouched(true)
  }

  const handleBlur = () => {
    if (touched) {
      if (number < min) {
        return setError(`${min} years minimum`)
      } else if (number > max) {
        return setError(`${max} years maximum`)
      } else {
        setNumber(Math.floor(parseInt(number)))
      }
    }
  }

  const handleSubmit = () => {
    if (number >= min && number <= max) {
      onQuestionAnswered(Math.floor(parseInt(number)))
    }
  }

  return (
    <Component>
      {error && (
        <Popup>
          <PopupText>{error}</PopupText>
        </Popup>
      )}
      <NumberInput
        type='number'
        inputMode='numeric'
        min={min}
        max={max}
        step={1}
        placeholder='Input your age'
        name='input'
        value={number}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button
        disabled={!touched && number <= max && number >= min}
        touched={touched && number <= max && number >= min}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Component>
  )
}

export default Input
