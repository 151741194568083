import styled from 'styled-components/macro'

const Title = styled.h3`
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '24px')};
  color: ${(props) => props.theme.colors.grey9};
  margin-top: ${(props) => (props.mtop ? '18px' : 0)};

  @media only screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 768px) {
    margin-top: 40px;
    justify-content: flex-end;
  }
`

export { Title, Content, ButtonWrapper }
