import 'intersection-observer'
import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { Router } from '@reach/router'

import theme from '../src/styles/theme'
import Home from './routes/Home/Home'
import Survey from './routes/Survey/Survey'
import data from './data/data'
import NotFound from './routes/NotFound/NotFound'
import Recommendations from './routes/Recommendations/Recommendations'
import Terms from './routes/Terms/Terms'
// import PdfViewer from './routes/PdfViewer/PdfViewer'

const routesData = [
  {
    path: '/'
  },
  {
    path: '/unisuper',
    fund: 'unisuper'
  },
  {
    path: '/mymentalwellbeing',
    fund: 'mymentalwellbeing'
  },
  {
    path: '/primesuper',
    fund: 'primesuper'
  },
  {
    path: '/awaresuper',
    fund: 'awaresuper'
  },
  {
    path: '/rest',
    fund: 'rest'
  },
  {
    path: '/tal',
    fund: 'tal'
  },
  {
    path: '/ngs',
    fund: 'ngs'
  },
  {
    path: '/wasuper',
    fund: 'wasuper'
  },
  {
    path: '/eiss-super',
    fund: 'eiss-super'
  },
  {
    path: '/red',
    fund: 'red'
  },
  {
    path: '/taladviser',
    fund: 'taladviser'
  },
  {
    path: '/vissf',
    fund: 'vissf'
  },
  {
    path: '/amp',
    fund: 'amp'
  }
]

const RouteWrapper = ({ children }) => <div>{children}</div>

const getAllUrlParams = () => {
  // get query string from url (optional) or window
  let queryString = window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0]
      let paramValue = typeof a[1] === 'undefined' ? true : a[1]

      // (optional) keep case consistent
      paramName = paramName.toLowerCase()
      if (typeof paramValue === 'string') {
        paramValue = paramValue.toLowerCase()
      }

      if (!obj[paramName]) {
        // if it doesn't exist, create property
        obj[paramName] = paramValue
      } else if (obj[paramName] && typeof obj[paramName] === 'string') {
        // if property does exist and it's a string, convert it to an array
        obj[paramName] = [obj[paramName]]
        obj[paramName].push(paramValue)
      } else {
        // otherwise add the property
        obj[paramName].push(paramValue)
      }
    }
  }

  return {
    source: obj.utm_source,
    medium: obj.utm_medium,
    campaign: obj.utm_campaign,
    video: obj.video
  }
}

const utmData = getAllUrlParams()

const getRoutes = () => {
  return routesData.map(({ path, fund }) => {
    return (
      <RouteWrapper path={path} key={path + fund}>
        <Home
          routePath={path}
          path='/'
          activateVideo={utmData.video}
          fund={fund}
        />
        <Survey
          routePath={path}
          fund={fund}
          path='survey'
          data={data}
          mediaParams={utmData}
        />
        <Recommendations path='recommendations' routePath={path} fund={fund} />
        <Terms path='terms' />
        {/* <PdfViewer path='pdf' /> */}
        <NotFound routePath={path} default />
      </RouteWrapper>
    )
  })
}

const App = (props) => (
  <ThemeProvider theme={theme}>
    <>
      <Router>{getRoutes()}</Router>
    </>
  </ThemeProvider>
)

export default App
