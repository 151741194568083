import React from 'react'
import styled from 'styled-components/macro'

import { SmallTitle, Text } from './Components'

const Component = styled.div`
  padding: 32px 24px;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 48px;
  }

  @media only screen and (min-width: 992px) {
    padding: 96px;
  }
`

const ContentWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    max-width: 1072px;
  }
`

const Privacy = () => (
  <Component id='privacy'>
    <ContentWrapper>
      <SmallTitle>Your Privacy</SmallTitle>
      <Text>
        The responses you contribute to this initiative help The University of
        Sydney Brain and Mind Centre understand the incidence of various risk
        factors in the general population in order to promote prevention. In
        order to ensure absolute privacy is maintained Headlight has been built
        in such a way that no identifying data whatsoever is retained.
      </Text>
    </ContentWrapper>
  </Component>
)

export default Privacy
