import React from 'react'
import styled from 'styled-components/macro'

import Content from './Content'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5625rem;
  margin-bottom: 2rem;
  flex: 1;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 0;
  }
`

const Result = ({ data, onFinishClick, isLoading, agreed, onAgreed, onDisagree }) => {
  const length = data.questions.length
  return (
    <Wrapper>
      <Content
        agreed={agreed}
        onAgreed={onAgreed}
        onDisagree={onDisagree}
        isLoading={isLoading}
        length={length}
        onFinishClick={onFinishClick}
      />
    </Wrapper>
  )
}

export default Result
