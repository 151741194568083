import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

const fade = keyframes`
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
`

const Component = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`

const Arrow = styled.div`
  animation: ${fade} 2s linear infinite;
  position: relative;
  width: 18px;
  height: 10px;
  display: block;
  opacity: 0.25;

  &:nth-of-type(2) {
    animation-delay: 0.25s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.5s;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${props => props.dark ? props.theme.colors.primaryAccent : '#fff'};
    border-radius: 4px;
    display: block;
    top: -5%;
    height: 110%;
    width: 19%;
  }

  &:before {
    left: 60%;
    transform: rotate(45deg);
  }

  &:after {
    right: 60%;
    transform: rotate(-45deg);
  }

  /* ie11 */
  @media screen and (min-width: 768px) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    margin: 0 auto;
  }
`

const Arrows = ({ dark }) => (
  <Component>
    <Arrow dark={dark} />
    <Arrow dark={dark} />
    <Arrow dark={dark} />
  </Component>
)

export default Arrows
