import React from 'react'
import { Document, Font } from '@react-pdf/renderer'

import ProximaNovaBold from '../../../fonts/ProximaNova-Bold.ttf'
import ProximaNovaRegular from '../../../fonts/ProximaNova-Regular.ttf'
import ProximaNovaRegularIt from '../../../fonts/ProximaNova-RegularIt.ttf'

import Main from './Main'
import Mental from './Mental'
import Lifestyle from './Lifestyle'
import Final from './Final'

Font.register({
  family: 'Proxima Nova Bold',
  src: ProximaNovaBold,
  fontStyle: 'normal',
  fontWeight: 'bold'
})

Font.register({
  family: 'Proxima Nova Regular',
  src: ProximaNovaRegular,
  fontStyle: 'normal',
  fontWeight: 'normal'
})

Font.register({
  family: 'Proxima Nova Regular Italic',
  src: ProximaNovaRegularIt,
  fontStyle: 'normal',
  fontWeight: 'normal'
})

const documentProps = {
  title: 'Headlight - Your Mental Wellbeing',
  author: 'Headlight',
  creator: 'Headlight',
  producer: 'Headlight'
}

// screen resolution: 595x842
// new figma design: 595x842
// figma / screen: 1
// print resolution: 2480x3508

const Pdf = ({ data }) => {
  const { dq5Inverted, recommendations } = data
  const { dq5, lifestyleFactors, headToHealth } = recommendations

  return (
    <Document {...documentProps}>
      <Main dq5Inverted={dq5Inverted} />
      <Mental dq5={dq5} lifestyleFactors={lifestyleFactors} />
      {lifestyleFactors && lifestyleFactors.modifiable && (
        <Lifestyle lifestyleFactors={lifestyleFactors} />
      )}
      <Final headToHealth={headToHealth} />
    </Document>
  )
}

// ReactPDF.render(<Pdf />, `${__dirname}/example.pdf`)

export default Pdf
