import React from 'react'
import { Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer'

import topImage from './img/top.png'
import bottomImage from './img/bottom.png'
import bottomPageImage from './img/bottom-page-image.png'
import Header from './Header'

const pageProps = {
  size: 'A4',
  wrap: false
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#42567a',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Regular'
  },
  content: {
    padding: 84,
    flex: 1,
    justifyContent: 'center'
  },
  innerContent: {},
  headerWrapper: {
    alignItems: 'center',
    marginBottom: 38
  },
  header: {
    color: '#f49188',
    fontSize: 33,
    fontFamily: 'Proxima Nova Bold'
  },
  scoreWrapper: {
    alignItems: 'center',
    marginBottom: 83
  },
  top: {
    width: 174,
    marginBottom: 7
  },
  score: {
    color: '#f49188',
    fontSize: 29,
    fontFamily: 'Proxima Nova Bold',
    marginBottom: 5,
    marginRight: 20
  },
  bottom: {
    width: 147
  },
  infoWrapper: {
    maxWidth: 290,
    alignSelf: 'center'
  },
  info: {
    color: '#F9F7F7',
    opacity: 0.6,
    fontSize: 13,
    fontFamily: 'Proxima Nova Regular Italic',
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: 1.5
  },
  bottomPageImageWrapper: {},
  bottomPageImage: {
    width: '100%'
  }
})

const Main = ({ dq5Inverted }) => (
  <Page {...pageProps} style={styles.page}>
    <Header inverted />
    <View style={styles.content}>
      <View style={styles.innerContent}>
        <View style={styles.headerWrapper}>
          <Text style={styles.header}>For the last 30 days,</Text>
          <Text style={styles.header}>your wellbeing score is…</Text>
        </View>
        <View style={styles.scoreWrapper}>
          <Image src={topImage} style={styles.top} />
          <Text style={styles.score}>{dq5Inverted}/25</Text>
          <Image src={bottomImage} style={styles.bottom} />
        </View>
        <View style={styles.infoWrapper}>
          <Text style={styles.info}>
            The higher your wellbeing 'score' the better your wellbeing has been
            within the past 30 days. Scores of 14 and below indicate a high risk
            for depression.
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.bottomPageImageWrapper}>
      <Image src={bottomPageImage} style={styles.bottomPageImage} />
    </View>
  </Page>
)

export default Main
