import React from 'react'
import styled from 'styled-components/macro'

import iconB from './back-b.svg'
import iconW from './back-w.svg'

const Icon = styled.img`
  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`

const Back = ({ type }) =>
  type === 'messageScreen' ? (
    <Icon src={iconW} alt='back' />
  ) : (
    <Icon src={iconB} alt='back' />
  )

export default Back
