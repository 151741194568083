import styled from 'styled-components/macro'

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 27px;
  line-height: 34px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 1rem;
  letter-spacing: -0.5px;

  @media only screen and (min-width: 992px) {
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 24px;
    text-align: center;
  }

  /* ie11 */
  /* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  } */
`

const SmallTitle = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 28px;
  line-height: 34px;
  color: ${(props) => props.theme.colors.primaryAccent};
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
`

const Text = styled.p`
  font-family: 'Proxima Nova Regular';
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  width: 100%;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
    letter-spacing: -0.25px;
  }
`

export { Title, SmallTitle, Text }
