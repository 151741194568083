import React from 'react'
import styled from 'styled-components/macro'

import alternativeIcon from './alternative.svg'

const Component = styled.div`
  display: none;
  width: 100%;
  position: absolute;
  /* ie11 fix */
  left: 0%;
  bottom: 0;

  background-image: url(${alternativeIcon});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media only screen and (min-width: 768px) {
    display: block;
    height: 60px;
  }

  @media only screen and (min-width: 992px) {
    height: 90px;
  }

  @media only screen and (min-width: 1400px) {
    height: 100px;
  }

  @media only screen and (min-width: 1600px) {
    height: 120px;
  }
`

const Scribble = () => <Component />

export default Scribble
