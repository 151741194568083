import React from 'react'
import styled from 'styled-components/macro'

import AlternativeScribble from '../Scribble/Alternative'
import Arrows from './Arrows'
import top from './top.svg'
import bottom from './bottom.svg'

const Content = styled.div`
  padding: 32px 24px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    padding: 4rem;
    padding-bottom: 188px;
    flex-direction: row;
    align-items: flex-start;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    justify-content: center;
  }
`

const Title = styled.h3`
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};

  @media only screen and (min-width: 992px) {
    text-align: left;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -2px;
    max-width: 432px;
    margin-right: 77px;
    margin-top: 48px;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`

const Addition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 60%;
  }
`

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 24px 0 32px 0;

  @media only screen and (min-width: 992px) {
    margin: 0 0 22px;
  }
`

const ScoreImage = styled.img`
  width: 271px;

  &:first-of-type {
    width: 327px;
    margin-bottom: 50px;
  }

  @media only screen and (min-width: 992px) {
    width: 340px;

    &:first-of-type {
      width: 410px;
      margin-bottom: 60px;
    }
  }
`

const Score = styled.h2`
  font-family: 'Proxima Nova Bold';
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media only screen and (min-width: 992px) {
    font-size: 56px;
    line-height: 64px;
    top: 51%;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 90%;
  }

  /* ie11 */
  @media screen and (min-width: 992px) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    width: 60%;
  }
`

const Info = styled.h4`
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: ${(props) => props.theme.colors.greyLight};
  opacity: 0.75;
  letter-spacing: 0.5px;

  @media only screen and (min-width: 992px) {
    max-width: 319px;
    text-align: center;
  }

  /* ie11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`

const ArrowsWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Suggestions = styled(Info)`
  margin-top: 20px;
  text-align: left;
  width: 100%;
  display: ${(props) => (props.noMobile ? 'none' : 'block')};
  font-weight: bold;

  @media only screen and (min-width: 768px) {
    display: ${(props) => (props.noDesktop ? 'none' : 'block')};
    margin-bottom: 20px;
  }
`

const Recommendation = ({ dq5Inverted }) => (
  <Content>
    <Title>For the last 30 days your wellbeing score is…</Title>
    <Addition>
      <Panel>
        <ScoreImage src={top} alt='Score top' />
        <Score>{dq5Inverted}/25</Score>
        <ScoreImage src={bottom} alt='Score bottom' />
      </Panel>
      <Info>
        <em>
          The higher your wellbeing 'score' the better your wellbeing has been
          within the past 30 days. Scores of 14 and below indicate a high risk
          for depression.
        </em>
      </Info>
    </Addition>
    <Suggestions noDesktop>
      <em>See your suggestions below...</em>
    </Suggestions>
    <ArrowsWrapper>
      <Suggestions noMobile>
        <em>See your suggestions below...</em>
      </Suggestions>
      <Arrows />
    </ArrowsWrapper>
    <AlternativeScribble color='white' />
  </Content>
)

export default Recommendation
