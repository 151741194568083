import React from 'react'
import { Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer'

import Header from './Header'
import Footer from './Footer'
import Link from './Link'
import smallScribble from './img/small-scribble.png'
import sharedStyles from './sharedStyles'

const pageProps = {
  size: 'A4',
  wrap: true
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F9F7F7',
    fontFamily: 'Proxima Nova Regular'
  },
  content: {
    padding: '0 84',
    flex: 1
  },
  innerContent: {
    width: 425
  },
  factor: {
    marginBottom: 70
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  titleWrapper: {
    alignSelf: 'center'
  },
  smallScribble: {
    width: 150
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  column: {
    width: '30%'
  }
})

const getFactors = (recommendations) => {
  const elements = []
  // it's an object, not an array
  Object.keys(recommendations).forEach((key) => {
    // overall item/section like cognitive, alcohol
    // can consists of multiple cards
    const item = recommendations[key]

    const element = (
      <View style={styles.factor} key={item.title + item.content[0].text}>
        <View style={styles.headerWrapper}>
          <View style={styles.titleWrapper}>
            <Text style={sharedStyles.subtitle}>{item.title}</Text>
            <Image src={smallScribble} style={styles.smallScribble} />
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.column}>
            <Text style={sharedStyles.smallTitle}>{item.content[0].title}</Text>
            <Text style={sharedStyles.textDensed}>{item.content[0].text}</Text>
            <Link
              url={item.content[2].card.link.url}
              label={item.content[2].card.link.label}
            />
          </View>
          <View style={styles.column}>
            <Text style={sharedStyles.smallTitle}>{item.content[1].title}</Text>
            <Text style={sharedStyles.textDensed}>{item.content[1].text}</Text>
          </View>
          <View style={styles.column}>
            <Text style={sharedStyles.smallTitle}>
              {item.content[2].card.title}
            </Text>
            <Text style={sharedStyles.textDensed}>
              {item.content[2].card.text}
            </Text>
          </View>
        </View>
      </View>
    )

    elements.push(element)
  })

  return elements
}

const Lifestyle = ({ lifestyleFactors }) => {
  const { recommendations } = lifestyleFactors.modifiable

  return (
    <Page {...pageProps} style={styles.page}>
      <Header />
      <View style={styles.content}>
        <View style={styles.innerContent}>{getFactors(recommendations)}</View>
      </View>
      <Footer />
    </Page>
  )
}

export default Lifestyle
