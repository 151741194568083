import React from 'react'
import styled from 'styled-components/macro'

const GeneralButton = styled.button`
  border: 0;
  background: none;
  border-radius: 4px;
  padding: 16px;
  white-space: nowrap;

  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(125%);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  @media only screen and (min-width: 768px) {
    padding: 16px 40px;
  }

  /* ie11 */
  @media only screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    min-width: 100px;
  }
`

const Primary = styled(GeneralButton)`
  background-color: ${(props) =>
    props.inverted
      ? props.theme.colors.primaryAccent
      : props.theme.colors.primary};
  color: ${(props) =>
    props.inverted ? '#fff' : props.theme.colors.primaryAccent};
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: auto;
  }
`

const Icon = styled.img`
  display: ${(props) => (props.noIconMobile ? 'none' : 'block')};
  width: ${(props) => (props.smallIcon ? '12px' : '14px')};

  @media only screen and (min-width: 768px) {
    display: block;
    width: 16px;
    margin-left: 12px;
  }
`

const TitleWrapper = styled.span`
  display: inline;
  margin-right: ${props => props.noIconMobile ? '0' : '8px'};
  font-size: 14px;
  line-height: 16px;

  @media only screen and (min-width: 768px) {
    display: inline;
    font-size: 20px;
    line-height: 32px;
    font-family: 'Proxima Nova Bold';
  }
`

const Button = ({
  onClick,
  title,
  icon,
  error,
  caption,
  inverted,
  invertedIcon,
  smallIcon,
  noIconMobile
}) => {
  return (
    <Primary
      onClick={error ? undefined : onClick}
      error={error}
      caption={caption}
      inverted={inverted}
    >
      <TitleWrapper noIconMobile>{title}</TitleWrapper>
      {(icon || invertedIcon) && (
        <Icon
          noIconMobile={noIconMobile}
          src={inverted ? invertedIcon : icon}
          alt={title}
          smallIcon={smallIcon}
        />
      )}
    </Primary>
  )
}

export default Button
