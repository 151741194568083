import React from 'react'
import styled from 'styled-components/macro'

import alcohol from './images/alcohol.svg'
import assertiveness from './images/assertiveness.svg'
import carer from './images/carer.svg'
import cognitive from './images/cognitive.svg'
import exercise from './images/exercise.svg'
import freedom from './images/freedom.svg'
import health from './images/health.svg'
import hours from './images/hours.svg'
import loneliness from './images/loneliness.svg'
import mental from './images/mental.svg'
import mindfulness from './images/mindfulness.svg'
import opportunities from './images/opportunities.svg'
import professional from './images/professional.svg'
import relationship from './images/relationship.svg'

const Image = styled.img`
  max-width: ${props => props.maxWidth}px;
`

// lonely — "loneliness"
// bullied — "assertiveness"
// physicalHealth — "health"
// mentalHealth — "mental"
// alcohol — "alcohol"
// carer — "carer"
// exercise — "exercise"
// workFreedom — "freedom"
// partner — "relationship"
// workOpportunities — "opportunities"
// workHours — "hours"

const getAccordionImage = (image, title) => {
  switch (image) {
    case 'loneliness':
      return <Image src={loneliness} alt={title} maxWidth={166} />
    case 'assertiveness':
      return <Image src={assertiveness} alt={title} maxWidth={166} />
    case 'health':
      return <Image src={health} alt={title} maxWidth={166} />
    case 'mental':
      return <Image src={mental} alt={title} maxWidth={166} />
    case 'alcohol':
      return <Image src={alcohol} alt={title} maxWidth={166} />
    case 'carer':
      return <Image src={carer} alt={title} maxWidth={166} />
    case 'exercise':
      return <Image src={exercise} alt={title} maxWidth={166} />
    case 'freedom':
      return <Image src={freedom} alt={title} maxWidth={166} />
    case 'relationship':
      return <Image src={relationship} alt={title} maxWidth={166} />
    case 'opportunities':
      return <Image src={opportunities} alt={title} maxWidth={166} />
    case 'hours':
      return <Image src={hours} alt={title} maxWidth={166} />
    case 'cognitive':
      return <Image src={cognitive} alt={title} maxWidth={166} />
    case 'mindfulness':
      return <Image src={mindfulness} alt={title} maxWidth={166} />
    case 'professional':
      return <Image src={professional} alt={title} maxWidth={166} />
    default:
      return <Image src={health} alt={title} maxWidth={166} />
  }
}

export default getAccordionImage
