import React from 'react'
import styled from 'styled-components/macro'

import getLink from '../utils/routeNavigation'

const Wrapper = styled.div`
  padding: 32px 24px;
  background-color: ${(props) => props.theme.colors.primary};

  @media only screen and (min-width: 768px) {
    padding: 40px 56px;
    display: flex;
    justify-content: center;
  }
`

const Content = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    max-width: 1328px;
    width: 100%;
  }
`

const LinksWrapper = styled.div`
  display: flex;
`

const Link = styled.a`
  font-family: 'Proxima Nova Regular';
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  /* used for rec floating element */
  margin-bottom: ${(props) => (props.biggerSpaceMobile ? '58px' : 0)};
  color: #fff;
  display: block;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
    text-decoration: underline;
  }

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
    color: #fff;
  }

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    margin-right: ${(props) => (props.mr ? '3rem' : 0)};
  }
`

const Copyright = styled.p`
  font-family: 'Proxima Nova Regular';
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 24px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
  }
`

const getDate = () => new Date().getFullYear()

const Footer = ({ routePath, biggerSpaceMobile, fund }) => (
  <Wrapper>
    <Content>
      <Copyright>Copyright Headlight {getDate()}</Copyright>
      <LinksWrapper>
        {fund === 'tal' && (
          <Link
            rel='noopener noreferrer'
            target='_blank'
            href='https://headlight.frill.co/b/nxmnry0l/feature-requests'
            biggerSpaceMobile={biggerSpaceMobile}
            mr
          >
            Feedback
          </Link>
        )}
        <Link
          rel='noopener noreferrer'
          target='_blank'
          href={getLink(routePath, '/terms')}
          biggerSpaceMobile={biggerSpaceMobile}
        >
          Terms of use and privacy
        </Link>
      </LinksWrapper>
    </Content>
  </Wrapper>
)

export default Footer
